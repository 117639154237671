import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import axios from '../../config/axios';
import * as constants from '../../constants';
import * as actionTypes from "@redux/Fleet/actions";

const URL = `${constants.FLEET_MS_API_URL}/fleet-management`;


function* deleteFleet(action) {
    try {
        const { id } = action.payload;
        const response = yield axios.delete(`${URL}/fleet/${id}`);

        if (response.status === 204) {
            yield put({ type: actions.DELETE_FLEET_SUCCESS, id });
        }
    } catch (error) {
        yield put({ type: actions.DELETE_FLEET_FAILURE, error });
    }
}

function* getQuickData(action) {
    try {
        let {fleetId} = action.payload;
        const response = yield axios.get(`${URL}/get-quick-tasks`, {
            params: action.payload
        });

        if (response?.status === 200) {
            const { data } = response.data;
            yield put({
                type: actionTypes.GET_QUICK_DATA_SUCCESS,
                data,
            });
        }
    } catch (error) {
        let payload = error.response.data;
        yield put({ type: actionTypes.GET_QUICK_DATA_FAILURE, payload });
    }
}

export default function* watcherSaga() {
    yield takeLatest(actions.DELETE_FLEET_REQUEST, deleteFleet);
    yield takeLatest(actions.GET_QUICK_DATA_REQUEST, getQuickData);
}
