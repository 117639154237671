import React from 'react'
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import {
    CurrencySymbol,
    CustomHeader, CustomInput, CustomInputWrapper, CustomTD,
    FleetButton, IconContainer,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {CURRENCY, getToday} from "../../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import axios from "axios";
import * as constants from "../../../../constants";

export default function QuotePreviewComponent({data, backToList, quote, vehicle, handleGeneratePDF, sendPdf, generating = false}) {

  return (
      <div className={'container-fluid mt-3'}>
        <BackActionComponent backAction={backToList} text={'Back to Edit'}/>
        <div className={'d-flex justify-content-between'}>
          <CustomHeader lineHeight={'unset'} >Repair Quote</CustomHeader>
          <div className={'d-flex'}>
                  <>
                      <FleetButton
                          height={'40px'}
                          disabled={generating}
                          width={'164px'}
                          backgroundColor={'#F3994A'}
                          radius={'8px'}
                          onClick={sendPdf}
                      >
                          Send
                      </FleetButton>
                      <FleetButton
                          height={'40px'}
                          width={'164px'}
                          disabled={generating}
                          backgroundColor={'#000000'}
                          radius={'8px'}
                          onClick={handleGeneratePDF}
                      >
                          Download pdf.
                      </FleetButton>
                  </>
          </div>
        </div>
        <Card>
          <CardHeader>
            <CustomHeader lineHeight={'none'} fontSize={'24px'} weight={'700'} color={'#000000'}>
              Invoice NO: {quote?.invoice_no}</CustomHeader>
          </CardHeader>
         <CardBody>
             <div className={'d-flex justify-content-between'}>
                 <div className={'d-flex flex-column'}>
                     <CustomHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                         BILLED TO:
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                         {vehicle.first_name}   {vehicle.last_name}
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         {vehicle.address}
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         {vehicle.mobile_number}
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         {vehicle.email}
                     </CustomHeader>
                 </div>
                 <div className={'d-flex flex-column'}>
                     <CustomHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                         FLEET INFORMATION:
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                         {vehicle.make.name} {vehicle.model.name}
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         {vehicle.derivative}
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         Reg no {vehicle.stock_no}
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         Color: {vehicle.colour}
                     </CustomHeader>
                 </div>
                 <div className={'d-flex flex-column'}>
                     <CustomHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                         ISSUED BY:
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                         GRS Motor Systems
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         City, Country
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         +1(000)000-00-00
                     </CustomHeader>
                     <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                         office@sample.com
                     </CustomHeader>
                 </div>
             </div>
             <StyledTable className={'mt-5 mb-5'}>
                 <thead>
                 <tr>
                     <StyledTableHeaderCell border={'none'} width={'33%'} color={'#0000008C'}>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                             Item
                         </CustomHeader>
                     </StyledTableHeaderCell>
                     <StyledTableHeaderCell border={'none'}  width={'33%'} color={'#0000008C'}>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                             Labour (£50/hour)
                         </CustomHeader>
                     </StyledTableHeaderCell>
                     <StyledTableHeaderCell border={'none'}  width={'33%'} color={'#0000008C'}>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                             Price
                         </CustomHeader>
                     </StyledTableHeaderCell>

                 </tr>
                 </thead>
                 <tbody>
                 {data.length ?
                     data.map((item, index)=>{
                         return <tr key={`4_${index}`}>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'33%'}>
                                 {item.title}
                             </CustomTD>

                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'33%'}>
                                 {item.hour}
                             </CustomTD>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'33%'}>
                                 {item.cost}
                             </CustomTD>
                         </tr>
                     })
                     : null
                 }
                 </tbody>
             </StyledTable>
             <div className={'d-flex justify-content-between'}>
                 <Col lg={4}>
                     <div className={'d-flex flex-column'}>
                         <CustomHeader lineHeight={'none'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                             PAYMENT INFORMATION:
                         </CustomHeader>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                             Bank Name:  Monzo
                         </CustomHeader>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                             Account name:   GRS Motor Group Ltd
                         </CustomHeader>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                             Sort Code:  04 00 03
                         </CustomHeader>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                             Account number:  4603 6895
                         </CustomHeader>
                         <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                             Payment Due:  {getToday()}
                         </CustomHeader>

                     </div>
                 </Col>
                 <Col lg={4}>
                     <StyledTable>
                         <tbody>
                         <tr>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                 <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                     Sum
                                 </CustomHeader>
                             </CustomTD>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                 <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                     {CURRENCY}  {quote.sum}
                                 </CustomHeader>
                             </CustomTD>

                         </tr>
                         <tr>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                 <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                     VAT {Math.round(Number(quote.discount))} %
                                 </CustomHeader>
                             </CustomTD>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                 <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                     {CURRENCY}  {quote.sum * (quote.discount / 100)}
                                 </CustomHeader>
                             </CustomTD>
                         </tr>
                         <tr>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                 <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                     TOTAL
                                 </CustomHeader>
                             </CustomTD>
                             <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'25%'}>
                                 <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                     {CURRENCY}  {quote.total}
                                 </CustomHeader>
                             </CustomTD>
                         </tr>
                         </tbody>
                     </StyledTable>
                 </Col>
               </div>

         </CardBody>
        </Card>
      </div>
  )
}