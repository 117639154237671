import React, {useEffect, useState} from 'react'
import axios from "axios";
import * as constants from "../../../constants";
import Cookies from "js-cookie";
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
    CheckboxContainer, Checkmark,
    CustomBadge, CustomHeader, CustomInput,
    CustomTableRow, CustomTD, FilterIconContainer, FleetButton, FlexInnerComponent, StatusSpan,
    StyledTable,
    StyledTableHeader,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import {departments, handleFocus, parseToWord} from "../../../constants";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import {
    Button,
    ButtonGroup, Col,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    PopoverBody,
    PopoverHeader, Row,
    UncontrolledPopover
} from "reactstrap";
import CustomSearch from "@pages/components/CustomSearch";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {DELETE_FLEET_REQUEST} from "@redux/Fleet/actions";
import fleetState from "@redux/Fleet/reducer";
import usePrevious from "../../../hooks/useprevious";
import {useHistory} from "react-router-dom";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import JobsTable from "@pages/new-design-components/JobsTable";
import QuotesTable from "@pages/new-design-components/QuotesTable";
import AddNewPartComponent from "@pages/components/JobDetailsComponents/AddNewPartComponent";

export default function  AwaitingParts() {
    const history = useHistory();
    const [token, setToken] = useState('');
    const [filter, setFilter] = useState({})
    const [tableData, setTableData] = useState([])
    const [deleteIds, setDeleteIds] = useState([])
    const [vehicle, setVehicle] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [quote, setQuote] = useState(null)
    const [parts, setParts] = useState([])
    const [departments, setDepartments] = useState([])
    const [showCreateNew, setShowCreateNew] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const keys =
        [

            {
                key: 'vehicle_make',
                text: 'Manufacturer'
            },
            {
                key: 'vehicle_model',
                text: 'Model'
            },
            {
                key: 'stock_no',
                text: 'Reg. No.'
            },
            {
                key: 'status',
                text: 'Status',
            },
            {
                key: 'view',
                text: 'View'
            },
        ];
    const fetchData = async (token,page = 1,filter = {}) => {
        const response = await axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-parts`,
            params: filter ? filter : '',
        })
        const data = response.data?.data

        const updatedData = data.map(item => {
            const hasIncompleteParts = item.parts.some(part => part.status !== 'completed');
            if (hasIncompleteParts) {
                item.status = 'to_do';
            } else {
                item.status = 'completed';
            }
            return item;
        });
        const res = await axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/departments`,
        });
        setDepartments(res.data.data?.departments);
        setTableData(updatedData)
    }


    const quickView = (index) => {
        const tempQuote = tableData[index]
        setQuote(tempQuote);
        setParts(tempQuote?.parts);
        setVehicle({
            id: tempQuote.vehicle.id,
            fleet_id: tempQuote.fleet_id,
            check_id: tempQuote.id,
            label: tempQuote.vehicle.stock_no,
        })
        setShowCreateNew(true);
    }
    const showDelete = async (index) => {
        const data = tableData[index]
        setDeleteId(data.id)
        setShowDeleteModal(true)

    }
    const deleteContent = async () => {
         const res = await axios({
             method: 'POST',
             url: `${constants.FLEET_MS_API_URL}/fleet-management/delete-fleet-part`,
             data: {id: deleteId}
         });
        if (res.status === 200) {
            console.log('success')
        }
    }
    useEffect(()=>{
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        fetchData(grs_token)
    },[])

    const searchData = async (key,value) => {
        const tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        await fetchData(token,1,tempFilter)
    }

    const changeData = ({index,key,value = null}) => {
        let tempData = [...parts]
        tempData[index][key] = value
        if (tempData[index]?.id) {
            tempData[index]['edited'] = true
        }
        setParts(tempData)
    }

    const toggle = () => {
        setShowCreateNew(!showCreateNew)
    }
    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }

    const deleteRow = ({index,id}) => {
        let tempData = [...parts];
        let tempIds = [...deleteIds]
        if (id && !tempIds.includes(id)) {
            tempIds.push(id)
            setDeleteIds(tempIds)
        }
        tempData.splice(index, 1);
        setParts(tempData)
    }

    const addNewRow = (quoteId) => {
        let tempData = [...parts]
        let row =  {
            part: null,
            department: null,
            part_number: null,
            cost: null,
            status: 'to_do',
            quote_id: quoteId,
        }
        tempData.push(row)
        setParts(tempData)
    }
    const getQuoteParts = (quoteId) => {
          axios({
              method: 'GET',
              url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-details`,
              params: {
                  quoteId,
              },
          }).then(res=>{
              const q = res.data.data
              setQuote(q)
              setParts(q.parts)
          }).catch(err=>{
              console.log('eee',err)
          })
    }

    const deleteParts = async (deleted) => {
        try {
            const promises = deleted.map(item=>{
                axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/delete-missing-part`, {id: item})
            })
            await Promise.all(promises);
        } catch (error) {
            console.error('Error while deleting parts:', error);
        }
    }
    const savePart = async (data) => {
        try {
            const promises = data.map(item=>{
                axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/save-part`,
                    {...item,fleet_id: quote.fleet_id,vehicle_id: quote.vehicle_id})
            })
            await Promise.all(promises);
        } catch (error) {
            console.error('Error while deleting parts:', error);
        }
    }
    const saveData = async () => {
        const created = parts.filter(item => item.id === undefined);
        const updated = parts.filter(item => item.edited === true);

        try {
            if (deleteIds.length) {
                await deleteParts(deleteIds)
            }
            await savePart([...created,...updated])

            await setVehicle(null)
            await setParts([])
            await setQuote(null)
            await toggle()
            await fetchData(token,1,filter)

        } catch (error) {
            console.error('Error while deleting images:', error);
        }
    }

    const clearAndClose = () => {
        setVehicle(null);
        setParts([]);
        setQuote(null);
        toggle();
    }
    return (
        <div className={'container-fluid mt-3'}>
            <Modal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                className="modal-dialog-centered"
            >
                <div className={'p-3'}>
                    <h6 className="modal-title">Are you sure you want to delete part?</h6>
                </div>

                <div className="modal-footer justify-content-end">
                    <Button
                        onClick={() => {
                            setShowDeleteModal(false)
                            setDeleteId(null)
                        }}
                    >
                        {`Close`}
                    </Button>
                    <Button
                        reverse
                        onClick={deleteContent}
                    >
                        {`Delete`}
                    </Button>

                </div>
            </Modal>
            <Modal
                isOpen={showCreateNew}
                toggle={clearAndClose}
                modalClassName={'slide-in-from-right'}
                backdrop={false}
                className={'modal-dialog-right modal-responsible'}
            >
                <ModalHeader toggle={clearAndClose} className={'bg-white centered-title'}>
                   <CustomHeader weight={'600'} fontSize={'14px'}  color={'#74787E'}>
                       Awaiting Parts
                   </CustomHeader>
                </ModalHeader>
                <ModalBody>
                    <AddNewPartComponent
                                         addNewRow={addNewRow}
                                         changeData={changeData}
                                         getQuoteParts={getQuoteParts}
                                         deleteRow={deleteRow}
                                         vehicle={vehicle}
                                         setVehicle={setVehicle}
                                         departments={departments}
                                         parts={parts}
                                        />
                </ModalBody>
                    <ModalFooter>
                       <div className={'d-flex flex-wrap g-10 w-100'}>
                           <FleetButton
                               height={'43px'}
                               width={'45%'}
                               backgroundColor={'#EB5855'}
                               color={'#FFFFFF'}
                               fontSize={'14px'}
                               radius={'8px'}
                               onClick={clearAndClose}
                           >
                               Close
                           </FleetButton>
                           <FleetButton
                               height={'43px'}
                               width={'45%'}
                               backgroundColor={'#F3994A'}
                               color={'#FFFFFF'}
                               fontSize={'14px'}
                               radius={'8px'}
                               onClick={saveData}
                           >
                               Save
                           </FleetButton>
                       </div>
                    </ModalFooter>
            </Modal>

            <CustomHeader className={'mb-5'}>
                Vehicles
            </CustomHeader>
            <div className={'d-flex justify-content-between'}>
                <div className={'d-flex g-10 w-50'}>
                    <FlexInnerComponent width={60}>
                        <CustomSearch
                            onChange={(e) => {
                                searchData('search', e.target.value)
                            }}
                            placeholder="Search"
                        />
                    </FlexInnerComponent>
                    <FlexInnerComponent width={60} >
                        <CustomDropdownComponent
                            border={'none'}
                            selectedTitle={filter?.inspectionStatus}
                            statusArrayKey={'jobListStatuses'}
                            handleChange={(data)=>{
                                searchData('inspectionStatus', data)
                            }}
                        />
                    </FlexInnerComponent>
                </div>
                <FleetButton
                    height={'43px'}
                    width={'214px'}
                    backgroundColor={'#000000'}
                    color={'#FFFFFF'}
                    fontSize={'14px'}
                    radius={'8px'}
                    onClick={()=>{
                        setShowCreateNew(true)
                    }}
                >
                    &#43; Add new
                </FleetButton>
            </div>

            <QuotesTable
                keys={keys}
                deleteContent={showDelete}
                quickView={quickView}
                withoutDelete={true}
                tableData={tableData}
            />
        </div>
    )
}