import React, {useEffect, useRef, useState} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    AddImageButton,
    CheckboxContainer,
    Checkmark, CrossIconContainer, CurrencySymbol,
    CustomHeader, CustomInput, CustomInputWrapper,
    CustomTD, FleetButton, IconContainer, StyledImage,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {CURRENCY} from "../../../constants";


export default function CheckInParts({
                                     data = [],
                                     departments = [],
                                     addNewRow,
                                     changeData,
                                     deleteRow,
                                     deleteDocument,
                                     onFileSelect,
                                 }) {

    const handleButtonClick = (id) => {
        document.getElementById(id).click();
    };

    const handleFileChange = (event,index) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file,index);  // Pass the selected file to the parent component
        }
    };
    return (
        <Col lg={12}>
            <Card className={'mb-3'}>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Parts Needed
                    </CustomHeader>
                </CardHeader>
                <CardBody className={'overflow-auto'}>
                    <StyledTable  minWidth={'750px'}  className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell fontSize={'14px'} width={'15%'} color={'#0000008C'}>
                                Part
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Department
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'15%'} color={'#0000008C'}>
                                No.
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'15%'} color={'#0000008C'}>
                                Cost
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell fontSize={'14px'} width={'15%'} color={'#0000008C'}>
                                {'Image'}
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell width={'10%'}>

                            </StyledTableHeaderCell>

                        </tr>
                        </thead>
                        <tbody>
                        {data.length ?
                            data.map((item, index)=>{
                                return <tr key={`4_${index}`}>
                                    <CustomTD className={'text-center'} width={'15%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'part',
                                                             value: e.target.value
                                                         })
                                                     }
                                                     }
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.part} />
                                    </CustomTD>
                                    <CustomTD width={'15%'}>
                                        <CustomDropdownComponent
                                            withoutDot
                                            border={'1px solid #C0D9FF59'}
                                            selectedTitle={item.department?.name}
                                            statusArrayKey={'departments'}
                                            handleChange={(value)=>{
                                                const department = departments.find(item=>item.name === value)
                                                changeData({
                                                    index,
                                                    key: 'department',
                                                    value: department
                                                })
                                            }}
                                        />
                                    </CustomTD>
                                    <CustomTD className={'text-center'} width={'15%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'part_number',
                                                             value: e.target.value
                                                         })
                                                     }
                                                     }
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.part_number} />
                                    </CustomTD>
                                    <CustomTD className={'text-center'} width={'15%'}>
                                        <CustomInputWrapper>
                                            <CurrencySymbol>{CURRENCY}</CurrencySymbol>
                                            <CustomInput
                                                radius={'8px'}
                                                padding={'10px 10px 10px 30px'}
                                                onChange={(e) => {
                                                    changeData({
                                                        index,
                                                        key: 'cost',
                                                        value: e.target.value,
                                                    });
                                                }}
                                                border={'1px solid #C0D9FF59'}
                                                value={item.cost}
                                                placeholder="Enter cost"
                                            />
                                        </CustomInputWrapper>
                                    </CustomTD>
                                    <CustomTD width={'15%'}>
                                        {
                                            item?.documents?.length ?
                                                <div className={'position-relative w-25'}>
                                                    <CrossIconContainer>
                                                        <IcoMoon
                                                            iconSet={IconSet}
                                                            icon={'cross'}
                                                            size={13}
                                                            onClick={() => {
                                                                deleteDocument(item?.documents[0]?.id,index)
                                                            }}
                                                        />
                                                    </CrossIconContainer>
                                                    <StyledImage width={'40px'}
                                                                 height={'40px'}
                                                                 src={item?.documents[0]?.path ? item?.documents[0]?.path : item?.documents[0]?.preview } alt=""/>
                                                </div> :
                                                <AddImageButton onClick={()=>handleButtonClick(`input_${index}`)}>
                                                    +
                                                    <input
                                                        type="file"
                                                        id={`input_${index}`}
                                                        style={{ display: 'none' }}
                                                        onChange={(e)=>{
                                                            handleFileChange(e,index)
                                                        }}
                                                    />
                                                </AddImageButton>
                                        }

                                    </CustomTD>
                                    <CustomTD width={'10%'}>
                                        <IconContainer>
                                            <IcoMoon
                                                className={'cursor-pointer'}
                                                iconSet={IconSet}
                                                icon={'delete_new_outline'}
                                                size={20}
                                                onClick={() => {
                                                    deleteRow(index)
                                                }}
                                            />
                                        </IconContainer>

                                    </CustomTD>
                                </tr>
                            })
                            : null
                        }
                        <tr>
                            <td colSpan="6" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewRow}
                                >
                                    &#43;  Add new
                                </FleetButton>
                            </td>
                        </tr>

                        </tbody>

                    </StyledTable>

                </CardBody>
            </Card>

        </Col>
    )
}