import React, {useState} from 'react'
import moment from "moment";

export const BASE_URL    = process.env.REACT_APP_BASE_URL;
export const SITE_CODE   = process.env.REACT_APP_SITE_CODE;
export const CURRENCY    = process.env.REACT_APP_CURRENCY;
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;

export const API_URL     = `${BASE_URL}/api/v1`;
export const API_CORE_URL = `${BASE_URL}/api/v1`;
export const FLEET_URL     = `${BASE_URL}/api/v2`;
export const STORAGE_URL = `${BASE_URL}/storage/`;

export const TRACKING_CLIENT_ID     = process.env.REACT_APP_TRACKING_CLIENT_ID;
export const TRACKING_CLIENT_SECRET = process.env.REACT_APP_TRACKING_CLIENT_SECRET;
export const TRACKING_URL           = process.env.REACT_APP_TRACKING_URL;

export const FLESPI_API_HOST  = process.env.REACT_APP_FLESPI_API_HOST;
export const FLESPI_API_TOKEN = process.env.REACT_APP_FLESPI_API_TOKEN;

export const PUSHER_APP_ID      = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_KEY     = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_SECRET  = process.env.REACT_APP_PUSHER_APP_SECRET;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const UK_VEHICLE_DATA_BASE_URL = process.env.REACT_APP_UK_VEHICLE_DATA_API_HOST;
export const UK_VEHICLE_DATA_API_URL  = `${UK_VEHICLE_DATA_BASE_URL}/api/datapackage`;
export const UK_VEHICLE_DATA_API_KEY  = process.env.REACT_APP_UK_VEHICLE_DATA_API_KEY;

// Microservices
export const FLEET_MS_BASE_URL = process.env.REACT_APP_MS_FLEET;
export const FLEET_MS_API_URL     = `${BASE_URL}/api`;

export const LIVE_VEHICLES_MS_API_URL     =  process.env.REACT_APP_MS_LIVE_VEHICLES
export const formatDate = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}`
  }
}

export const formatToDatePickerValue = (d) => {
if (d) {
  let formatted = d.split("-")
  return  {
    day: parseInt(formatted[0]),
    month: parseInt(formatted[1]),
    year: parseInt(formatted[2]),
  };
}
}
export const formatDateShortGB = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${da}/${mo}/${ye}`
  }
}

export const checkAndFormatShortGb = (d) => {
  if (d &&  ((d.includes('T') && d.includes('Z')) || (d.includes('t') && d.includes('z')))) {
    let date = new Date(d);
    let ye = date.getUTCFullYear();
    let mo = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    let da = date.getUTCDate().toString().padStart(2, '0');

    return `${da}/${mo}/${ye}`;
  } else return d
}
export const formatDateFullGB = (d) => {
  if (d) {
    let date = new Date(d);

    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    }

    let formatted = Intl.DateTimeFormat('en-BG', options).format(date);

    return formatted
  }
}

export const formatDateHoursSeconds = (d) => {
  if (d) {
    let date = new Date(d);
    let ye = Intl.DateTimeFormat( 'en', { year: 'numeric' }).format(date);
    let mo = Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    let da = Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let ho = Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(date);
    let mi = Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);

    return `${ye}-${mo}-${da}T${ho}:${mi}`
  }
}

export const getRandomColor = () => {
  let letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getColorsForSources = (labels) => {
  let colors = localStorage.getItem('sourcesColors') !== null ? JSON.parse(localStorage.getItem('sourcesColors')) : {};

  let bgColors = [];
  let shouldUpdate = false;

  if (Array.isArray(labels)) {
    labels.forEach(element => {
      if (colors[element]) {
        bgColors.push(colors[element]);
      } else {
        let tempColor = getRandomColor();
        bgColors.push(tempColor);
        colors[element] = tempColor;
        shouldUpdate = true;
      }
    });
  } else {
    if (colors[labels]) {
      return colors[labels]
    } else {
      let tempColor = getRandomColor();
      colors[labels] = tempColor;
      window.localStorage.setItem('sourcesColors', JSON.stringify(colors));

      return tempColor;
    }
  }

  if (shouldUpdate) {
    window.localStorage.setItem('sourcesColors', JSON.stringify(colors))
  }

  return bgColors;
}

export const formatString = (str, num) => {
  return str.slice(0, num) + '..';
}

export const convertKmToMile = (val) => {
  return Math.round(val * 0.621371);
}
export const parseToWord = (name) => {
  if (name) {
    let text = name.replace(/_+/g, ' ').toLowerCase();
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}

export const  convertMinutesToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60); // Divide by 60 to get hours
    const minutes = totalMinutes % 60;          // Get the remainder for minutes
    return { hours, minutes };
}

export const parseToKey = (name) => {
       if (name) {
         return name.replace(/\s+/g, '_').toLowerCase();
       }
    }

export const returnFirstLetter = (word) => {
  return word?.charAt(0).toUpperCase();
}
export const propertiesSumInArray = (array,key) => {
 return  array.reduce((val, object) => {
    return Number(val) + Number(object?.[key]);
  }, 0)
}

export const formatCalendarDate = (date) => {
   if (date) {
     return `${date.year}-${date.month}-${date.day}`
   }
   else return ''
}

export const getYears = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  let startYear = 2022;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const  vehicleStatuses =
    {
      available: "Available",
      pending: "Pending",
      awarded: "Awarded",
      out_on_rent: "Out on rent",
      sold: "Sold"
    }

export const  activeStatuses =
    {
      1: "Yes",
      0: "No",
    }
export const jobCategories =   {
  1: "Mechanical", 2: "Valeting", 4: "Body shop", 5: "Ply lining", 6: "Parts"
}

export const taskStatuses = {
    'new': 'New request',
    'in_progress' : 'In progress',
    'completed': 'Completed'
}

export const isEmailValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export const validateForm = (requiredFields,formData) => {
  const hasEmail = requiredFields.includes('email')
  return (
      requiredFields.every(field => {
          if (formData && formData[field]) {
              return Array.isArray(formData[field]) ? formData[field].length : formData[field]
          }
      } ) &&
      (hasEmail ? isEmailValid(formData['email']) : true)
  );
};

export const cutEmail = (email) => {
    let emailRegex = /[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}/;

    return email.match(emailRegex);
}
export const formatEmailText = (email) => {
    return email.replace(/<[^>]+>/g, '');
}
export const parseGmailDate = (date) => {

    let newDate = moment(date, "ddd, DD MMM YYYY HH:mm:ss Z");

    return  newDate.format("DD/MM/YYYY");

}

export const timeAtAddressType  = {
        "1": "1 Year",
        "2": "2 Years",
        "3": "3 Years",
        "4": "4 Years",
        "5": "5 Years +"
}

export const titles  = {
        "miss": "Miss",
        "mrs": "Mrs",
        "mr": "Mr"
 }

export const dealStatuses = {
    'renewing': 'Renewing',
    'purchased_on_retention': 'Purchased on retention',
    'pending_payment' : 'Awaiting order',
    'paid_order_fee' : 'Paid order fee',
    'order_signed' : 'Order signed',
    'initial_payment_paid' : 'Initial payment paid',
    'delivered' : 'Delivered',
 }
export const fleetStatuses = [
    {
        value: 'to_do',
        text: 'To do'
    },
    {
        value: 'in_progress',
        text: 'In progress'
    },
    {
        value: 'in_review',
        text: 'In review'
    },
    {
        value: 'completed',
        text: 'Completed'
    }
]

export const fleetPriorities = [
    {
        label: 'High',
        value: 'high',
        color: 'red',
        icon: 'ep_d-arrow-up-double.png'
    },
    {
        label: 'Medium',
        value: 'medium',
        color: 'green',
        icon: 'fluent_line-equal.png'
    },
    {
        label: 'Low',
        value: 'low',
        color: 'Blue',
        icon: 'ep_arrow-down.png'
    }
]
 export const intervals = {
    day: "Day(s)",
    month: "Month(s)",
    week:"Week(s)"
 }

export const paymentIntervals = {
    days: "Days",
    months: "Months",
    weeks:"Weeks"
}
export const highlightText = (text,replacement) => {
    const highlightedText = text.replace(
        new RegExp(`(${replacement})`, 'gi'),
        '<span style="background-color: #fde293">$1</span>'
    );
     return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
 }

export const monthsObj = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
]

export const isObject = (obj) => {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
}
export const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
};

export const badgeColors = (type, opacity = 1) => {
    const color = (() => {
        switch (type) {
            case 'valeting':
            case 'sold_at_auction':
            case 'new':
                return '#3080EB';
            case 'body shop':
            case 'body_shop':
                return '#9B52E1';
            case 'mechanics':
            case 'mechanical':
                return '#F3CA4D';
            case 'miscellaneous':
            case 'ply lining':
                return '#F3994A';
            case 'medium':
                return '#FF947E';
            case 'high':
                return '#EB5855';
            case 'low':
                return '#FCBC4D';
            case 'to_do':
                return '#EB5757';
            case 'completed':
            case 'trade_sale':
                return '#219852';
            case 'in_progress':
                return '#F3994A';
            case 'parts':
            case 'written_off':
            case 'archived':
                return '#8D8D8D';
            case 'all':
                return '#000000';
            default:
                return '#e36c6c';
        }
    })();

    // Convert hex to RGB and then to RGBA
    const rgb = parseInt(color.slice(1), 16); // Convert hex to int
    const r = (rgb >> 16) & 0xff; // Extract red
    const g = (rgb >>  8) & 0xff; // Extract green
    const b = (rgb >>  0) & 0xff; // Extract blue

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const handleFocus = (ref, id) => {
    ref.current.type = "date"
    const dateInput = document.getElementById(id);
    if (dateInput && typeof dateInput.showPicker === 'function') {
        dateInput.showPicker();
    }
    if (ref.current) {
        ref.current.focus();
    }
};

export const getToday = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${day}/${month}/${year}`;
}

export const departments =
{
     mechanics:'mechanical',
     valeting: 'valeting',
     miscellaneous: 'miscellaneous',
     body_shop: 'body_shop',
};

export const fleetNewStatuses =
    {
        jobListStatuses:
            [
                {
                    title: 'All statuses',
                    key: null,
                },
                {
                    title: 'Completed',
                    key: 'completed',
                    color: '#219852',
                },
                {
                    title: 'Not Started',
                    key: 'not_started',
                    color: '#EB5757',
                },
                {
                    title: 'In Progress',
                    key: 'in_progress',
                    color: '#F3994A',
                },
            ],
        fleetTasksStatuses:
            [
                {
                    title: 'All statuses',
                    key: null,
                },
                {
                    title: 'Completed',
                    key: 'completed',
                    color: '#219852',
                },
                {
                    title: 'Not Started',
                    key: 'to_do',
                    color: '#EB5757',
                },
                {
                    title: 'In Progress',
                    key: 'in_progress',
                    color: '#F3994A',
                },
            ],
        interiorStatuses:  [
             {
                title: 'Checked and OK',
                key: 'checked_ok',
                color: '#219852',
             },
             {
                 title: 'Requires immediate attention',
                 key: 'requires_attention',
                 color: '#EB5757',
             },
             {
                 title: 'May require attention soon',
                     key: 'may_require_attention',
                 color: '#F3994A',
             },
        ],

        departments: [
            {
                title: 'Mechanical',
                key: 'mechanical',
                color: '#219852',
            },
            {
                title: 'Valeting',
                key: 'valeting',
                color: '#EB5757',
            },
            {
                title: 'Miscellaneous',
                key: 'ply lining',
                color: '#9ceb57',
            },

            {
                title: 'Body Shop',
                key: 'body shop',
                color: '#9B52E1',
            },
            {
                title: 'Parts',
                key: 'parts',
                color: '#8D8D8D',
            },
        ],

        checkInStatuses: [
            {
                title: 'Fail',
                key: true,
                color: '#EB5757',
            },
            {
                title: 'Pass',
                key: false,
                color: '#219852',
            },
        ],

        quotePassStatuses:
            [

                {
                    title: 'Completed',
                    key: 'completed',
                    color: '#219852',
                },
                {
                    title: 'Not Started',
                    key: 'to_do',
                    color: '#EB5757',
                },
            ],
    }


export const paginateArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};

export const recommendedServices =
  [
      {
          icon: 'inspectionImages/oil_service.svg',
          slug: 'service',
      },
      {
          icon: 'inspectionImages/transmission_fluid.svg',
          slug: 'transmission_flush',
      },
      {
          icon: 'inspectionImages/coolant_flush.svg',
          slug: 'coolant_flush',
      },
      {
          icon: 'inspectionImages/brake_service.svg',
          slug: 'brake_service',
      },
      {
          icon: 'inspectionImages/engine_flush.png',
          slug: 'engine_flush',
      },
      {
          icon: 'inspectionImages/brake_fluid.svg',
          slug: 'brake_fluid_flush',
      },
      {
          icon: 'inspectionImages/wheel_alignment.svg',
          slug: 'wheel_alignment',
      },
      {
          icon: 'inspectionImages/intake_value_cleaning.svg',
          slug: 'intake_value_cleaning',
      },
      {
          icon: 'inspectionImages/throttle_cleaning.svg',
          slug: 'throttle_cleaning',
      },
      {
          icon: 'inspectionImages/battery_maintenance.png',
          slug: 'battery_maintenance',
      },
  ]

export const damageOptions = {
    body: [
        {key: 'scratch', value: 'Scratch'},
        {key: 'stone_chip', value: 'Stone Chip'},
        {key: 'prd', value: 'PDR'},
        {key: 'dent', value: 'Dent'},
        {key: 'rust', value: 'Rust'},
        {key: 'respray', value: 'Respray'},
        {key: 'realignment', value: 'Realignment'},
        {key: 'replace', value: 'Replace'},
    ],
    inside: [
        {key: 'scratch', value: 'Scratch'},
        {key: 'load_area', value: 'Load Area'},
        {key: 'broken', value: 'Broken'},
        {key: 'rip', value: 'RIP'},
    ],
};
export const damagesPosition = {
    front: 'Front Side',
    back: 'Back Side',
    left: 'Left Side',
    right: 'Right Side',
    doors: 'Doors',
    inside: 'Inside',
    top: 'Roof',
    tyre_wear: 'Tyre Wear',
};
export const fleetStatus = {
    '1': 'new',
    '2': 'in_progress',
    '3': 'completed',
    '4': 'archived',
}
export const tyreConditions = [
        {key: 'pass', value: 'Pass'},
        {key: 'fail', value: 'Fail'},
]

export const componentOptions = {
    front: [
        {key: 'front_bumper', value: 'Bumper'},
        {key: 'bonnet', value: 'Bonnet'},
        {key: 'windscreen', value: 'Windscreen'},
        {key: 'head_lights', value: 'Head lights'},
    ],
    left: [
        {key: 'front_wing', value: 'N/S Front wing'},
        {key: 'side', value: 'N/S Side'},
        {key: 'ns_wing_mirror', value: 'N/S Wing mirror'},
        {key: 'rear_quarter', value: 'N/S Rear Quarter'},
        {key: 'front_wheel', value: 'N/S Front Wheel'},
        {key: 'rear_wheel', value: 'N/S Rear Wheel'},
    ],
    right: [
        {key: 'os_front_wing', value: 'O/S Front wing'},
        {key: 'os_side', value: 'O/S Side'},
        {key: 'os_wing_mirror', value: 'O/S Wing mirror'},
        {key: 'os_rear_quarter', value: 'O/S Rear Quarter'},
        {key: 'os_front_wheel', value: 'O/S Front Wheel'},
        {key: 'os_rear_wheel', value: 'O/S Rear Wheel'},
    ],
    back: [
        {key: 'rear_bumper', value: 'Bumper'},
        {key: 'rear_lights', value: 'Lights'},
        {key: 'tail_gate', value: 'Tail Gate'},
    ],
    inside: [
        {key: 'driver_seat', value: 'Driver Seat'},
        {key: 'passenger_seat', value: 'Passenger Seat'},
        {key: 'warning_lights', value: 'Warning Lights'},
        {key: 'rear_seats', value: 'Rear Seats'},
        {key: 'ply_lining', value: 'Ply Lining'},
        {key: 'plastic_trim', value: 'Plastic Trim'},
        {key: 'floor', value: 'Floor'},
        {key: 'sound_system', value: 'Sound System'},
    ],
    top: [{key: 'roof', value: 'Roof'}],
    doors: [
        {key: 'driver_door', value: 'Driver door'},
        {key: 'passenger_door', value: 'Passenger door'},
        {key: 'driver_rear_door', value: 'Driver rear door'},
        {key: 'passenger_rear_door', value: 'Passenger rear door'},
        {key: 'os_side_loading', value: 'O/S Side loading'},
        {key: 'ns_side_loading', value: 'N/S Side loading'},
    ],
    wheel: [
        {key: '6+mm', value: '6+mm'},
        {key: '6mm', value: '6mm'},
        {key: '5mm', value: '5mm'},
        {key: '4mm', value: '4mm'},
        {key: '3mm', value: '3mm'},
        {key: '2mm', value: '2mm'},
        {key: '1mm', value: '1mm'},
        {key: 'less', value: 'Less than 1mm'},
    ],
}