import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import axios from "../../../config/axios";
import * as constants from "../../../constants";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import ServiceRecordPreviewComponent from "@pages/components/JobDetailsComponents/ServiceRecordPreviewComponent";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import ServiceRecordComponentForPrint from "@pages/components/JobDetailsComponents/ServiceRecordComponentForPrint";
import {CustomHeader, FleetButton} from "@pages/new-design-components/custom-components";

export default function EditVehicleParts() {
    const location = useLocation();
    const [loading,setLoading] = useState(true);
    const [vehicle,setVehicle] = useState({});
    const [data,setData] = useState([]);
    const history = useHistory();
    const { vehicleId, fleetId, quoteId = null } = location.state || {};


    useEffect(() => {
        if (quoteId) {
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-details`,
                params: {
                    quoteId,
                },
            }).then(res=>{
                const q = res.data.data
                setData(q.parts)
            }).catch(err=>{
                console.log('eee',err)
            })
        }
    },[quoteId])

    useEffect(()=>{
        if (vehicleId) {
            try {
                axios({
                    method: 'GET',
                    url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-vehicle`,
                    params: {
                        vehicleId,
                    },
                }).then(res=>{
                    const data = res.data.data
                    setVehicle(data.vehicle)
                    setLoading(false)
                }).catch(err=>{
                    setLoading(false)
                })

            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                }
            }
        }
    },[vehicleId])


    const redirectToManagement = () => {
        history.push({
            pathname: '/admin/awaiting-parts'
        })
    }


    const handleGeneratePDF = () => {
        const input = document.getElementById('pdf-content');

        html2canvas(input, {
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF('p', 'mm', 'a4');
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('generated-pdf.pdf');
        });
    };

    const sendPdf = () => {
        const input = document.getElementById('pdf-content'); // The content you want to convert to PDF

        html2canvas(input, {
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF('p', 'mm', 'a4');
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            const pdfBlob = pdf.output('blob');

            const formData = new FormData();

            const pdfFileName = `vehicle_${vehicleId}_generated.pdf`;
            formData.append('vehicle_id', vehicleId);
            formData.append('pdf', pdfBlob, pdfFileName);
            formData.append('type', 'service_report');
            axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/save-report`,
                data: formData
            }).then(res=>{
            }).catch(err=>{
            })
        });
    };

    return (
        loading ? <></>
            :
            <div className={'container-fluid mt-3'}>
                <div className={'d-flex align-items-center text-center w-100 flex-column mt-3'}>
                    <ServiceRecordPreviewComponent
                        backToList={()=>{redirectToManagement()}}
                        text={'Back to list'}
                        vehicle={vehicle}
                        handleGeneratePDF={handleGeneratePDF}
                        sendPdf={sendPdf}
                        vehicleId={vehicleId}
                        partsData={data}/>
                </div>
                   <ServiceRecordComponentForPrint
                       parsable={true}
                       vehicle={vehicle}
                       partsData={data}
                   />
            </div>
    )
}