import React from 'react'
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import {
    CurrencySymbol,
    CustomHeader, CustomInput, CustomInputWrapper, CustomTD,
    FleetButton, IconContainer, PdfHeader,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import {CURRENCY} from "../../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import axios from "axios";
import * as constants from "../../../../constants";
import {Notification} from "@pages/components/Notification";

export default function ServiceRecordPreviewComponent({ backToList,
                                                          vehicle, sendPdf,
                                                          handleGeneratePDF,
                                                          text = 'Back to Edit',
                                                          total = 0,
                                                          partsData,
                                                          totalLabour,
                                                          dataTotal,
                                                          data
}) {
    return (
        <div className={'container-fluid mt-3'}>
            <BackActionComponent backAction={backToList} text={text}/>
            <div className={'d-flex justify-content-between'}>
                <CustomHeader lineHeight={'unset'} >Repair Quote</CustomHeader>
                <div className={'d-flex'}>
                    <>
                        <FleetButton
                            height={'40px'}
                            width={'264px'}
                            backgroundColor={'#F3994A'}
                            radius={'8px'}
                            onClick={sendPdf}
                        >
                            Create Service Record
                        </FleetButton>
                        <FleetButton
                            height={'40px'}
                            width={'164px'}
                            backgroundColor={'#000000'}
                            radius={'8px'}
                            onClick={handleGeneratePDF}
                        >
                            Download pdf.
                        </FleetButton>
                    </>
                </div>

            </div>
            <Card  className={'table-container'}>
                <CardBody>
                    <div className={'d-flex justify-content-between table-container'}>
                        <div className={'d-flex flex-column pt-3 align-items-start'}>
                            <CustomHeader lineHeight={'14px'} fontSize={'20px'} weight={'700'} color={'#000000'} className={'mb-4'}>
                                FLEET INFORMATION:
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                {vehicle.make.name} {vehicle.model.name}
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                {vehicle.derivative}
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Reg no {vehicle.stock_no}
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Color: {vehicle.colour}
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Mileage: {vehicle.current_mileage}
                            </CustomHeader>
                        </div>
                        <div className={'d-flex flex-column text-center pt-3'}>
                             <img className={'mb-3'} height='140px' width='360px'
                                  src={require(`assets/images/GRSMotorGroupLogo.svg`)} alt=""/>
                            <CustomHeader lineHeight={'none'} fontSize={'24px'} weight={'700'} color={'#000000'}>
                                Service Record
                            </CustomHeader>
                         </div>
                        <div className={'d-flex flex-column pt-3 align-items-start'}>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'700'} color={'#000000'}>
                                GRS Motor Group Ltd
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Unit 12 & 13
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Priory Park
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Thornton
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Milford Haven
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                SA73 2DA
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Tel: 01646 603872
                            </CustomHeader>
                            <CustomHeader lineHeight={'10px'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                Vat No: 249 1787 67
                            </CustomHeader>

                        </div>
                    </div>
                    <div className={'table-container'}>
                        <StyledTable>
                            <thead>
                            <tr>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Work
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Labour(h)
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Cost
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        VAT 20%
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Total
                                    </CustomHeader>
                                </StyledTableHeaderCell>

                            </tr>
                            </thead>
                            <tbody>
                            <tr key={`4_13G`}>
                                <CustomTD border={'none'} radius={'0px'} width={'20%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        Service
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'} width={'20%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        1.5
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'}  width={'20%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        £250
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'}  width={'20%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        £50
                                    </CustomHeader>
                                </CustomTD>
                                <CustomTD border={'none'} radius={'0px'} width={'20%'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                        £300
                                    </CustomHeader>
                                </CustomTD>

                            </tr>
                            </tbody>
                        </StyledTable>
                        <StyledTable className={'mb-5 '}>
                            <thead>
                            <tr>
                                <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Part
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Quantity
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Part No.
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Cost per Unit
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                                <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                    <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                        Total
                                    </CustomHeader>
                                </StyledTableHeaderCell>
                            </tr>
                            </thead>
                            <tbody>
                            {partsData.length ?
                                partsData.map((item, index)=>{
                                    return <tr key={`4_${index}`}>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                                {item.part}
                                            </CustomHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                                {item.quantity}
                                            </CustomHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                                {item.part_number}
                                            </CustomHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                                {item.cost}
                                            </CustomHeader>
                                        </CustomTD>
                                        <CustomTD border={'none'} radius={'0px'} borderBottom={'1px solid #C0D9FF'} width={'20%'}>
                                            <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                                {parseFloat(item.quantity) * parseFloat(item.cost)}
                                            </CustomHeader>
                                        </CustomTD>
                                    </tr>
                                })
                                : null
                            }
                            </tbody>
                        </StyledTable>

                        <div className={'d-flex pt-3 g-10 align-items-start'}>
                            <StyledTable>
                                <thead>
                                <tr>
                                    <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                            Cost
                                        </CustomHeader>
                                    </StyledTableHeaderCell>

                                    <StyledTableHeaderCell border={'none'} width={'20%'} color={'#0000008C'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                            VAT 20%
                                        </CustomHeader>
                                    </StyledTableHeaderCell>
                                    <StyledTableHeaderCell border={'none'}  width={'20%'} color={'#0000008C'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'600'} color={'#000000'}>
                                            Total
                                        </CustomHeader>
                                    </StyledTableHeaderCell>

                                </tr>
                                </thead>
                                <tbody>
                                <tr key={`4_13G`}>

                                    <CustomTD border={'none'} radius={'0px'}  width={'20%'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            £{total}
                                        </CustomHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'}  width={'20%'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            £{(parseFloat(total) * 0.20).toFixed(2)}
                                        </CustomHeader>
                                    </CustomTD>
                                    <CustomTD border={'none'} radius={'0px'} width={'20%'}>
                                        <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'400'} color={'#000000'}>
                                            £{total + (total * 0.20)}
                                        </CustomHeader>
                                    </CustomTD>

                                </tr>
                                </tbody>
                            </StyledTable>

                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}