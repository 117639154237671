import {Form, FormGroup, Input} from "reactstrap";
import {AddImageButton, CustomHeader, CustomInput} from "@pages/new-design-components/custom-components";
import CustomDamageDropDown from "@pages/components/JobDetailsComponents/CustomDropDown";
import {componentOptions, tyreConditions} from "../../../constants";
import {Text} from "@pages/reusable-components/styled-components";
import React from "react";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

export const TyreDamagesForm = ({wheelDamage,selectWheelDamage}) => (
    <Form>
        <div className={'d-flex g-10'}>
            <img height='30px' width='60px'
                 src={require(`assets/images/VehicleInspection/front_right.png`)}
                 alt=""/>
            <FormGroup className="mb-3">
                <CustomInput radius={'8px'}
                             value={wheelDamage?.ns_rear_tyre?.depth}
                             onChange={(e) => {
                                 selectWheelDamage('ns_rear_tyre', 'depth', e.target.value);
                             }}
                             className={'mb-2'}
                             border={'1px solid #d9d9d9'}
                />
                <CustomDamageDropDown
                    data={tyreConditions}
                    selectedTitle={wheelDamage?.ns_rear_tyre?.condition}
                    handleChange={(e)=>{
                        selectWheelDamage('ns_rear_tyre', 'condition', e)
                    }}
                />

            </FormGroup>
        </div>
        <div className={'d-flex g-10'}>
            <img height='30px' width='60px'
                 src={require(`assets/images/VehicleInspection/back_right.png`)}
                 alt=""/>
            <FormGroup className="mb-3">
                <CustomInput radius={'8px'}
                             value={wheelDamage?.ns_front_tyre?.depth}
                             onChange={(e) => {
                                 selectWheelDamage('ns_front_tyre', 'depth', e.target.value);
                             }}
                             className={'mb-2'}
                             border={'1px solid #d9d9d9'}
                />
                <CustomDamageDropDown
                    data={tyreConditions}
                    selectedTitle={wheelDamage?.ns_front_tyre?.condition}
                    handleChange={(e)=>{
                        selectWheelDamage('ns_front_tyre', 'condition', e)
                    }}
                />
            </FormGroup>
        </div>
        <div className={'d-flex g-10'}>
            <img height='30px' width='60px'
                 src={require(`assets/images/VehicleInspection/front_left.png`)}
                 alt=""/>
            <FormGroup className="mb-3">
                <CustomInput radius={'8px'}
                             value={wheelDamage?.os_rear_tyre?.depth}
                             onChange={(e) => {
                                 selectWheelDamage('os_rear_tyre', 'depth', e.target.value);
                             }}
                             className={'mb-2'}
                             border={'1px solid #d9d9d9'}
                />
                <CustomDamageDropDown
                    data={tyreConditions}
                    selectedTitle={wheelDamage?.os_rear_tyre?.condition}
                    handleChange={(e)=>{
                        selectWheelDamage('os_rear_tyre', 'condition', e)
                    }}
                />
            </FormGroup>

        </div>
        <div className={'d-flex g-10'}>
            <img height='30px' width='60px'
                 src={require(`assets/images/VehicleInspection/back_left.png`)}
                 alt=""/>
            <FormGroup className="mb-3">
                <CustomInput radius={'8px'}
                             value={wheelDamage?.os_front_tyre?.depth}
                             onChange={(e) => {
                                 selectWheelDamage('os_front_tyre', 'depth', e.target.value);
                             }}
                             className={'mb-2'}
                             border={'1px solid #d9d9d9'}
                />
                <CustomDamageDropDown
                    data={tyreConditions}
                    selectedTitle={wheelDamage?.os_front_tyre?.condition}
                    handleChange={(e)=>{
                        selectWheelDamage('os_front_tyre', 'condition', e)
                    }}
                />
            </FormGroup>

        </div>
        <div className={'d-flex  g-10 mb-3'}>
            <div className={'d-flex flex-column  g-10'}>
                <Text>
                    Spare Tyre
                </Text>
                <img height='45px' width='45px'
                     src={require(`assets/images/VehicleInspection/spare.png`)}
                     alt=""/>
            </div>
            <div className="custom-control custom-radio align-self-end ">
                <input onChange={() =>  selectWheelDamage('spare_tyre', 'condition', 'yes')}
                       checked={wheelDamage?.spare_tyre?.condition === 'yes'}
                       className={'cursor-pointer mr-2'}
                       id={'yes'} type="radio"/>
                <label className={' cursor-pointer'} htmlFor={`yes`}>
                    Yes
                </label>
            </div>
            <div className="custom-control custom-radio align-self-end ">
                <input onChange={() => selectWheelDamage('spare_tyre', 'condition', 'no')}
                       checked={wheelDamage?.spare_tyre?.condition === 'no'}
                       className={'cursor-pointer mr-2'}
                       id={'no'} type="radio"/>
                <label className={' cursor-pointer'} htmlFor={`no`}>
                    No
                </label>
            </div>
        </div>
    </Form>
)

export const BodyworkDamageFrom = ({damageComponent,modalCurrentDamageData,setModalCurrentDamageData,damageSelectedOptions,onFilesChange,inputRef}) => (
    <Form>
        <FormGroup className="mb-3">
            <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'700'} color={'#74787E'}>
                Damage Position
            </CustomHeader>
            <CustomDamageDropDown
                data={componentOptions[damageComponent]}
                selectedTitle={modalCurrentDamageData.position}
                handleChange={(e)=>{
                    setModalCurrentDamageData({
                        ...modalCurrentDamageData,
                        position: e
                    })
                }}
                disabled={!componentOptions[damageComponent]?.length}
            />
        </FormGroup>
        <FormGroup className="mb-3">
            <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'700'} color={'#74787E'}>
                Damage
            </CustomHeader>
            <CustomDamageDropDown
                data={damageSelectedOptions}
                selectedTitle={modalCurrentDamageData.damage}
                handleChange={(e)=>{
                    setModalCurrentDamageData({
                        ...modalCurrentDamageData,
                        damage: e
                    })
                }}
                disabled={!modalCurrentDamageData?.position}
            />

            <div className={'d-flex mt-3'}>
                {modalCurrentDamageData?.imagesPreview?.length > 0 ? modalCurrentDamageData?.imagesPreview.map((image, idx) => {
                    return <div key={idx + 'par'} className={'mr-2 '}>
                        <div
                            style={{width: "max-content"}}
                            className={'position-relative'}>
                            <img
                                key={idx + 'img'}
                                width={56}
                                height={56}
                                src={image} alt=""/>
                            <IcoMoon
                                iconSet={IconSet}
                                icon={'trash'}
                                size={15}
                                style={{display: 'none'}}
                                onMouseDown={() => {
                                    setModalCurrentDamageData({
                                        ...modalCurrentDamageData,
                                        images: modalCurrentDamageData?.images.filter((itm, index) => index !== idx),
                                        imagesPreview: modalCurrentDamageData?.imagesPreview.filter((itm, index) => index !== idx),
                                    })
                                }}
                                className={'remove-icon-style'}
                                key={idx}
                            />
                        </div>
                    </div>
                }) : null
                }
                {modalCurrentDamageData?.images?.length > 0 ? modalCurrentDamageData?.images.map((image, idx) => {

                    return  image.hasOwnProperty('image_link') && <div key={idx + 'par'} className={'mr-2 '}>
                        <div
                            style={{width: "max-content"}}
                            className={'position-relative'}>
                            <img
                                key={idx + 'img'}
                                width={56}
                                height={56}
                                src={image.image_link} alt=""/>

                        </div>
                    </div>
                }) : null
                }
                <AddImageButton width={'56px'} onClick={()=>inputRef.current.click()}>
                    +
                    <input
                        disabled={!componentOptions[damageComponent]?.length}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            onFilesChange(e)
                        }}
                        ref={inputRef}
                        multiple={'multiple'}
                        name="myImage" accept="image/png, image/gif, image/jpeg"
                    />
                </AddImageButton>
            </div>
        </FormGroup>
    </Form>
)