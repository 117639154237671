import { combineReducers } from 'redux';
import emailTemplateState from './Email/reducer';
import taskState from './Task/reducer';
import getState from './Auth/reducer';
import dealerState from './Dealer/reducer';
import personalisationState from './Personalisation/reducer';
import leadState from '@redux/Lead/reducer';
import reportState from '@redux/Report/reducer';
import dealState from './Deal/reducer';
import paymentState from '@redux/Payments/reducer';
import fleetState from '@redux/Fleet/reducer';
import customerSignatureState from '@redux/Signature/reducer';
import vehicle from '@redux/Vehicle/reducer';
import {loader} from './General/loader';

const rootReducer = combineReducers({
  getState,
  taskState,
  dealerState,
  leadState,
  reportState,
  personalisationState,
  emailTemplateState,
  dealState,
  paymentState,
  customerSignatureState,
  vehicle,
  fleetState,
  loader
});

export default rootReducer;
