import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    CheckboxContainer,
    Checkmark,
    CustomHeader, CustomInput,
    CustomTD, FleetButton, IconContainer,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {useHistory, useLocation} from "react-router-dom";
import * as constants from "../../../constants";
import axios from "axios";
import Valeting from "@pages/components/JobDetailsComponents/Valeting";
import BodyShop from "@pages/components/JobDetailsComponents/BodyShop";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";


export default function JobDetails() {
 const [loading,setLoading] = useState(true);
 const [data,setData] = useState({});
 const [fleetTasks,setFleetTasks] = useState([]);
 const [partsData,setPartsData] = useState([]);
 const [deleteIds,setDeleteIds] = useState([]);
 const [deletePartIds,setDeletePartIds] = useState([]);
 const [deleteDocumentIds,setDeleteDocumentIds] = useState([]);
 const location = useLocation();
 const history = useHistory();
 const { vehicleId, fleetId, department } = location.state || {};
    const fetchData = () => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-task/vehicle`,
            params: {
                vehicleId,
                fleetId,
                department
            }
        }).then(res=>{
            setData(res.data.data[0])
            setFleetTasks(res.data.data[0].fleet_vehicle?.tasks)
            setPartsData(res.data.data[0].parts)
            setLoading(false)
        }).catch(err=>{
            setLoading(false)
        })
    }

    useEffect(() => {
        if (vehicleId && fleetId && department) {
            fetchData()
        }
    }, [vehicleId, fleetId,department]);

    const addNewBodyShopRow = () => {
        let tempData = [...fleetTasks]
        let row =  {
            fleet_id: data.fleet_vehicle?.id,
            title: '',
            "description": "",
            "priority": "medium",
            job_category: 'body_shop',
            documents: [],
            status: 'to_do',
            "completed_at": null,
            "started_at": null,
            "note": "",
            "price": 0,
            "options": null,
            is_auto_generated: false,
            "fleet_category_option_id": null,
            "department_id": data.department_id,
        }
        tempData.push(row)
        editValetingData(tempData)
    }

    const addNewPartsRow = () => {
        let tempData = [...partsData]
        let row =  {
            fleet_id: data.fleet_vehicle?.id,
            department_id: data.department_id,
            vehicle_id: vehicleId,
        }
        tempData.push(row)
        setPartsData(tempData)
    }
    const addNewValetingRow = () => {
        let tempData = [...fleetTasks]
        let row =  {
            fleet_id: data.fleet_vehicle?.id,
            title: '',
            "description": "",
            "priority": "medium",
            job_category: department,
            status: 'to_do',
            "completed_at": null,
            "started_at": null,
            "note": "",
            "price": 0,
            "options": null,
            is_auto_generated: false,
            "fleet_category_option_id": null,
            "department_id": data.department_id,
        }
        tempData.push(row)
        editValetingData(tempData)
    }

    const editValetingData = (tempData) => {
        setFleetTasks(tempData);
    }
    const changeValetingData = ({index,key,value = null}) => {
        let tempData = [...fleetTasks]
        if(key === 'status') {
            tempData[index].status = (tempData[index].status === 'completed' ? 'to_do' : 'completed')
        } else {
            tempData[index][key] = value
        }
        if (tempData[index]?.id) {
            tempData[index]['edited'] = true
        }
        editValetingData(tempData)
    }
    const changePartsData = ({index,key,value = null}) => {
        let tempData = [...partsData]
            tempData[index][key] = value
        if (tempData[index]?.id) {
            tempData[index]['edited'] = true
        }
        setPartsData(tempData)
    }
    const deleteBodyShopRow = (index) => {
        let tempData = [...fleetTasks];
        let taskToDelete = tempData[index];
        let deleteIdsTemp = [...deleteIds];
        if (taskToDelete?.id) {
            deleteIdsTemp.push(taskToDelete.id);
        }
        tempData.splice(index, 1);
        setDeleteIds(deleteIdsTemp)
        editValetingData(tempData)
    }
    const deletePartsRow = (index) => {
        let tempData = [...partsData];
        let taskToDelete = tempData[index];
        let deleteIdsTemp = [...deletePartIds];
        if (taskToDelete?.id) {
            deleteIdsTemp.push(taskToDelete.id);
        }
        tempData.splice(index, 1);
        setDeletePartIds(deleteIdsTemp)
        setPartsData(tempData)
    }

    const deleteValetingRow = (index) => {
        let tempData = [...fleetTasks];
        let taskToDelete = tempData[index];
        let deleteIdsTemp = [...deleteIds];
        if (taskToDelete?.id) {
            deleteIdsTemp.push(taskToDelete.id);
        }
        tempData.splice(index, 1);
        setDeleteIds(deleteIdsTemp)
        editValetingData(tempData)
    }
    const deleteTaskDocument = (id,index) => {
        let tempData = [...fleetTasks];
        if (id) {
            tempData[index].documents = [];

            const docs = [...deleteDocumentIds]
            docs.push(id)
            setDeleteDocumentIds(docs)
        } else {
            tempData[index].documents = [];
        }
        setFleetTasks(tempData)
    }
    const handleFileSelect = async (file, index) => {
        if (file) {
            let tempData = [...fleetTasks];
            if (!tempData[index].documents) {
                tempData[index].documents = [];
            }
            let reader = new FileReader();
            reader.readAsDataURL(file)
            const preview =  URL.createObjectURL(file)
            if (tempData[index]?.id) {
                tempData[index]['edited'] = true
            }
            tempData[index].documents.push({
                preview,
                file,
            });

            setFleetTasks(tempData);
        }
    }

    const deleteTasks = async (deleted) => {
        const promises = deleted.map(item =>
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${item}/archive`)
        );

        try {
            await Promise.all(promises);
            setDeleteIds([])
            console.log('All delete tasks have been archived successfully.');
        } catch (error) {
            console.error('Error while deleting tasks:', error);
        }
    }

    const updateTasks = async (updated) => {
        if (updated.length) {
            const promises = updated.map(async (item) => {
                const formData = new FormData();
                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'documents') continue;
                        if (key === 'department_id' && item[key] === null ) {
                            formData.append(key, data.department_id);
                        } else {
                            formData.append(key, item[key]);
                        }
                    }
                }


                if (item.documents && item.documents.length > 0) {
                    for (const document of item.documents) {
                        if (document.file) {
                            formData.append('documents[]', document.file);
                        }
                    }
                }
                try {
                    const response = await axios.post(
                        `${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/${item?.id}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data', // Important for file uploads
                            },
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error('Error while updating task:', error);
                    throw error;
                }
            });
            try {
                await Promise.all(promises);
                console.log('All tasks have been updated successfully.');
            } catch (error) {
                console.error('Error while updating tasks:', error);
            }
        }
    };
    const deleteImages = async (deleted) => {
        try {
            const response = await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/task/images/delete`,{
                ids: deleted
            })
            if (response.status == '200') {
                setDeleteDocumentIds([]);
            }
        } catch (error) {
            console.error('Error while deleting images:', error);
        }
    }

    const createTasks = async (created) => {
        if (created.length) {
            const promises = created.map(async (item) => {
                const formData = new FormData();

                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        if (key === 'documents') continue;
                        formData.append(key, item[key]);
                    }
                }

                if (item?.documents?.length) {
                    for (const document of item.documents) {
                        if (document.file) {
                            formData.append('documents[]', document.file);
                        }
                    }
                }
                try {
                    const response = await axios.post(
                        `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${data.fleet_vehicle?.id}/task`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error('Error while creating task:', error);
                    throw error;
                }
            });

            try {
                await Promise.all(promises);
                console.log('All new tasks have been created successfully.');
            } catch (error) {
                console.error('Error while creating tasks:', error);
            }
        }

    };

    const createParts = async (created) => {
        const promises = created.map(item =>
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/parts`, item)
        );

        try {
            await Promise.all(promises);
            console.log('All new tasks have been created successfully.');
        } catch (error) {
            console.error('Error while creating tasks:', error);
        }
    }
    const updateParts = async (updated) => {
        const promises = updated.map(item =>
            axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/parts/update`, item)
        );

        try {
            await Promise.all(promises);
            console.log('All new tasks have been created successfully.');
        } catch (error) {
            console.error('Error while creating tasks:', error);
        }
    }

    const deleteParts = async (deleted) => {
        try {
            const response = await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/fleet/parts/delete`,{
                ids: deleted
            })
            if (response.status == '200') {
                setDeletePartIds([]);
            }
        } catch (error) {
            console.error('Error while deleting parts:', error);
        }
    }

    const updateWorkingRecords = async () => {
        setLoading(true);
        const created = fleetTasks.filter(item => item.id === undefined);
        const updated = fleetTasks.filter(item => item.edited === true);
        const createdPartsData = partsData.filter(item => item.id === undefined);
        const updatedPartsData = partsData.filter(item => item.edited === true);
        try {
            if (deleteDocumentIds?.length) {
                await deleteImages(deleteDocumentIds);
            }
            if (deletePartIds.length) {
                await deleteParts(deletePartIds)
            }
            await Promise.all([
                createTasks(created),
                updateTasks(updated),
                deleteTasks(deleteIds),
                createParts(createdPartsData),
                updateParts(updatedPartsData),
            ]);

            await fetchData()
        } catch (error) {
            console.error('Error while processing tasks:', error);
        } finally {
            setLoading(false);
        }
    }
    const redirectToManagement = () => {
        history.push({
            pathname: '/admin/fleet-vehicles-list'
        })
    }
 return (
     loading ? <div/> :
        <div className={'container-fluid mt-3'}>
            <BackActionComponent backAction={redirectToManagement} text={'Back to overview'}/>
            <div className={'d-flex justify-content-between'}>
                    <CustomHeader lineHeight={'unset'} >Job Card</CustomHeader>
                    <FleetButton
                        height={'40px'}
                        width={'164px'}
                        backgroundColor={'#F3994A'}
                        radius={'8px'}
                        onClick={updateWorkingRecords}
                    >
                        Save
                    </FleetButton>
            </div>
            <Row className={'mt-4'}>
                <Col lg={3}>
                    <Card className={'fleet-details-container'}>
                        <CardHeader className={'border-bottom-0'}>
                            <CustomHeader fontSize={'16px'}>
                                Fleet Details
                            </CustomHeader>
                        </CardHeader>
                        <CardBody>
                            <img src="" alt="" className={'fleet-task-image'} height={'164px'}/>
                            <div className={'d-flex flex-column'}>
                                <CustomHeader weight={'600'} className={'mb-0'}>
                                    {`${data.make.name} ${data.model.name}`}
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.make.name} ${data.model.name}`}
                                </CustomHeader>
                            </div>
                            <div className={'d-flex flex-column'}>
                                <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                                    Registration Number
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.stock_no}`}
                                </CustomHeader>
                            </div>
                            <div className={'d-flex flex-column'}>
                                <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                                    Mileage
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.current_mileage}`}
                                </CustomHeader>
                            </div>
                            <div className={'d-flex flex-column'}>
                                <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                                    Colour
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.colour}`}
                                </CustomHeader>
                            </div>
                            <div className={'d-flex flex-column'}>
                                <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                                    Customer
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.first_name}`}
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.address}`}
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.mobile_number}`}
                                </CustomHeader>
                                <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                                    {`${data.email}`}
                                </CustomHeader>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
                {
                    department === 'body shop' ?
                        <BodyShop
                            data={fleetTasks}
                            addNewRow={addNewBodyShopRow}
                            addNewPartsRow={addNewPartsRow}
                            changeData={changeValetingData}
                            deleteRow={deleteBodyShopRow}
                            deleteDocument={deleteTaskDocument}
                            onFileSelect={handleFileSelect}
                            partsData={partsData}
                            changePartsData={changePartsData}
                            deletePartsRow={deletePartsRow}
                        /> : (department === 'valeting' || department === 'ply lining') ?
                            <Valeting
                                data={fleetTasks}
                                department={department}
                                addNewRow={addNewValetingRow}
                                changeData={changeValetingData}
                                deleteRow={deleteValetingRow}
                            /> : null
                }

            </Row>

        </div>
    )
}