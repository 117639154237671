import React, {useEffect, useState, useRef, createRef, useMemo} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import NotificationAlert from 'react-notification-alert'
import styled from "styled-components";
import axios from 'axios'
import Cookies from 'js-cookie'

import * as constants from 'constants/index.js'
import CardsHeader from 'layouts/Headers/CardsHeader.js'

import actions from 'actions/index.js';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    FormGroup, Input, ModalHeader, ModalBody, Form, ModalFooter, Table
} from 'reactstrap'
import CustomChecklist from "../components/CustomChecklist";
import VehicleInfo from "../vehicles/VehicleInfo";
import {
    ButtonContainer,
    SignContainer,
    TextContainer
} from "@pages/reusable-components/styled-components";
import VehicleDamageComponent from "@pages/components/VehicleDamageComponent";
import {
    componentOptions,
    damageOptions,
    damagesPosition,
    parseToKey,
    parseToWord,
    tyreConditions
} from "constants/index.js";
import SignatureCanvas from "react-signature-canvas";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";

const Text = styled.span`
 font-weight: ${props => props.bold ? '600' : '400'};
 color: ${props => props.bold ? '#35353E' : '#4E4E56'};
 font-size:  ${props => props.size ? props.size : '16px'}
`

export default function JobCreate() {
    const notificationAlert = useRef(null)
    const history = useHistory()
    const eSign = useRef(null);
    const dispatch = useDispatch()
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [showDamageModal, setShowDamageModal] = useState(false)
    const [showTyreModal, setShowTyreModal] = useState(false)
    const [deleteModal, showDeleteModal] = useState(false)
    const [deleteDetails, setDeleteDetails] = useState({})
    const [wheelDamage, setWheelDamage] = useState({spare_tyre: 'yes'});
    const [damageData, setDamageData] = useState({})
    const [modalCurrentDamageData, setModalCurrentDamageData] = useState({})
    const [damageModalHeaderText, setDamageModalHeaderText] = useState(null)
    const [regNos, setRegNos] = useState([])
    const [filter, setFilter] = useState('')
    const [vehicle, setVehicle] = useState('')
    const [mileage, setMileage] = useState('')
    const [categories, setCategories] = useState({})
    const [requiredData, setRequiredData] = useState(true)
    const [pendingRequest, setPendingRequest] = useState(false)
    const inputRef = useRef()

    const [damageComponent, setDamageComponent] = useState(null)

    const [damageSelectedOptions, setDamageSelectedOptions] = useState([])

    const generalCategories = useSelector((state) => state.getState).categories;
    useEffect(() => {
        setRequiredData(!!Object.values(generalCategories).find(item => Object.values(item).find(value => value.is_passing === null)))
    }, [generalCategories])
    const notify = (type, header, message) => {
        const options = {
            place: "tc",
            message: (
                <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
              {header}
          </span>
                    <span data-notify="message">
            <div dangerouslySetInnerHTML={{__html: message}}/>
          </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }
    const fetchData = async (reg_no) => {
        try {
            const source = axios.CancelToken.source()
            const response = await axios({
                method: 'GET',
                url: `${constants.FLEET_URL}/fleet-management/vehicles/available`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                  reg_no: reg_no,
                  withMedia: 1
                },
                cancelToken: source.token,
            })
            const data = response.data

            let _regNos = data.data.regNos;
            let _regNos_array = [];
            _regNos.map(_regNo => {
                return _regNos_array.push({
                    ..._regNo,
                    value: _regNo.id,
                    label: _regNo.stock_no,
                })
            })
            setRegNos(_regNos_array)
            return _regNos_array;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled :: ', error)
            } else {
                console.error('error :: ', error)
                notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
            }
        }
    }
    const promiseOptions = inputValue => {
        if (inputValue.length < 2) {
            return
        } else {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(fetchData(inputValue));
                }, 1000);
            });
        }
    }


    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
    }, [])
    const clear = () => {
        eSign.current.clear()
    }


    const searchVehicle = () => {
        if (filter && filter.regNo) {
            fetchJobs(1)
        } else {
            notify('info', 'Information', 'Please select a reg no.')
        }
    }

    const fetchJobs = async (pageNumber) => {
        try {
            const categories = await axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/category-options`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    page: pageNumber
                }
            })
            let tempCategories = {}
            categories.data.data.categories.forEach(item => {
                let categoryName = parseToKey(item.name)
                item.options.forEach(val => {
                    tempCategories[categoryName] = {
                        ...tempCategories[categoryName],
                        [parseToKey(val.name)]:
                            {
                                'is_passing': true,
                                'id': val.id
                            }
                    }
                })
            })
            dispatch(actions.setCategories(tempCategories))
            setCategories(tempCategories)
            setTimeout(() => {
                setLoading(false)
            }, 500);
        } catch (error) {
            notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
        }
    }

    const changeCategories = (data) => {
        dispatch(actions.setCategories({...categories, [data.name]: data.value}))
    }

    const selectDamage = (damage) => {
        setDamageModalHeaderText(damagesPosition[damage])
        setDamageComponent(damage)
        setDamageSelectedOptions(damageOptions[damage] ? damageOptions[damage] : damageOptions.body)
        let data = {
            position: '',
            damage: '',
            note: '',
            images: [],
            imagesPreview: []
        }
        setModalCurrentDamageData({...data})
        toggleDamageModal()
    }

    const selectTyre = () => {
        toggleTyreModal()
    }
    const toggle = () => setModal(!modal);
    const toggleDamageModal = () => setShowDamageModal(!showDamageModal);

    const toggleDeleteModal = () => showDeleteModal(!deleteModal);

    const onFilesChange = (e) => {
        let images = []
        let imagesPreview = []
        if (modalCurrentDamageData.images.length) {
            images = [...modalCurrentDamageData.images]
        }
        if (modalCurrentDamageData.imagesPreview.length) {
            imagesPreview = [...modalCurrentDamageData.imagesPreview]
        }

        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            images.push(file)
            let reader = new FileReader();
            reader.readAsDataURL(file)
            imagesPreview.push(URL.createObjectURL(file))
        }
        setModalCurrentDamageData({...modalCurrentDamageData, images: images, imagesPreview: imagesPreview})
    }
    const saveChanges = () => {
        let tempData = damageData;
        let modalTempData = {...modalCurrentDamageData};
        modalTempData.type = damageModalHeaderText;
        if (!tempData[damageComponent]) {
            tempData[damageComponent] = []
        }
        tempData[damageComponent].push(modalTempData)
        setDamageData(tempData)
        setModalCurrentDamageData({})
        toggleDamageModal()
    }

    const updateDamage = () => {
        let tempData = damageData;
        let modalTempData = {...modalCurrentDamageData};
        modalTempData.type = damageModalHeaderText;
        if (!tempData[damageComponent]) {
            tempData[damageComponent] = []
        }
        tempData[deleteDetails.component][deleteDetails.index] = modalTempData
        setDamageData(tempData)
        setModalCurrentDamageData({})
        setDeleteDetails({})
        toggleDamageModal()
    }
    const toggleTyreModal = () => setShowTyreModal(!showTyreModal);
    const sendData = async (value) => {
        setPendingRequest(true)
        let values = {}
        let arr = []
        Object.keys(generalCategories).forEach((item) => {
            arr.push(...Object.values(generalCategories[item]))
        })
        values.pdi_checklist = arr
        values.note = ''
        values.vehicle_id = vehicle.id
        values.mileage_in = mileage
        values.create_tasks_on_save = value
        const response = await axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet`,
            data: values,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        let fleetId = response?.data?.data.id;

        wheelDamage.fleetId = fleetId
        wheelDamage.vehicleId = vehicle.id
        await axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-wheel-damage`,
            data: wheelDamage,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        let tempDamageData = Object.values(damageData).flat()
        if(!eSign.current.isEmpty()) {
            let formDataSignature = new FormData()
            formDataSignature.append('fleetId', fleetId);
            formDataSignature.append('vehicleId', vehicle.id);
            formDataSignature.append('type', 'signature');
            formDataSignature.append('files', eSign.current.getTrimmedCanvas().toDataURL('image/png'))
            try {
                const response = await axios({
                    method: 'POST',
                    url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-damage`,
                    data: formDataSignature,
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                clear()
            } catch (error) {
                notify('warning', 'Error', error)
            }
        }
        tempDamageData.forEach((item)=>{
            let formData = new FormData()
            formData.append('damage', item.damage);
            formData.append('position', item.position);
            formData.append('note', item.note);
            formData.append('type', item.type);
            formData.append('fleetId', fleetId);
            formData.append('vehicleId', vehicle.id);
            if (item.images.length > 0) {
                item.images.forEach((image,index)=>{
                    formData.append(`files[${index}]`, image);
                })
            }
            const res = axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-damage`,
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        })

        if (response.status === 200 || response.status === 201) {
            if (value) {
                history.push(`/admin/vehicle-details?id=${fleetId}`)
            }
        }
        setPendingRequest(false)
        setModal(false)
    }
    const editDamage = (component,index) => {
        setDeleteDetails({component:component,index:index})
        setModalCurrentDamageData({...damageData[component][index]})
        toggleDamageModal()
    }
    const deleteTempDamage = (component,index) => {
        setDeleteDetails({component:component,index:index})
        toggleDeleteModal()

    }
    const confirmDeleteDamage = () => {
        let tempData = {...damageData};
        setDamageData(tempData)
        setDeleteDetails({})
        toggleDeleteModal()
    }
    const cancelDeleteDamage = () => {
        setDeleteDetails({})
        toggleDeleteModal()
    }
    const selectWheelDamage = (tyre, tyre_damage_type, val) => {
        let tempDamage = {...wheelDamage};
        if (!Object.keys(tempDamage).includes(tyre)) {
            tempDamage[tyre] = {};
        }
        tempDamage[tyre][tyre_damage_type] = val;
        tempDamage[tyre]['position'] = tyre;
        setWheelDamage(tempDamage);
    };
    const checkSignature = () => {
        if (!eSign?.current?.isEmpty()) {
            setModal(true)
        } else {
            notify('warning', 'Warning', 'Please sign first!')
        }
    }
    const itemsRef = useMemo(() => Array(modalCurrentDamageData?.imagesPreview?.length).fill().map(() => createRef()), [modalCurrentDamageData?.imagesPreview]);

    return (
        <>
            <Modal isOpen={deleteModal} className="modal-dialog-centered">
                <div className="modal-body">
                    {`Are you sure you want to delete this damage?`}
                </div>
                <ModalBody>
                    <div className="modal-footer justify-content-end">
                        <ButtonContainer
                            onClick={() => cancelDeleteDamage()}
                        >
                            Cancel
                        </ButtonContainer>
                        <ButtonContainer
                            reverse
                            onClick={() => confirmDeleteDamage()}
                        >
                            Delete
                        </ButtonContainer>
                    </div>
                </ModalBody>
            </Modal>
            <Modal backdrop={false} className={'modal-dialog-right'} isOpen={showDamageModal}
                   toggle={()=>{
                       setModalCurrentDamageData({})
                       setDeleteDetails({})
                       toggleDamageModal()
                   }}>
                <div className={'modal-header flex-column'}>
                    <h2 className={'main-color'}>
                        Add damage
                    </h2>
                    <Text bold>
                        {damageModalHeaderText}
                    </Text>
                </div>
                <ModalBody>
                    <Form>
                        <FormGroup className="mb-3">
                            <Input
                                type="select"
                                style={{width: "100%"}}
                                onChange={e => {
                                    setModalCurrentDamageData({
                                        ...modalCurrentDamageData,
                                        position: componentOptions[damageComponent][e.target.value]?.key
                                    })
                                }}
                                disabled={false}
                            >
                                <option>{Object.keys(deleteDetails).length && modalCurrentDamageData.hasOwnProperty('position') &&
                                modalCurrentDamageData.position !== '' ?
                                    componentOptions[deleteDetails.component].find(item=>item.key === modalCurrentDamageData.position).value
                                    : 'Select Damage Position'}</option>
                                {componentOptions[damageComponent] &&
                                componentOptions[damageComponent].map((key, index) => {
                                    return <option value={index} key={key + '_' + index}>{key.value}</option>
                                })
                                }
                            </Input>

                        </FormGroup>
                        <FormGroup className="mb-3">

                            <Input
                                type="select"
                                style={{width: "100%"}}
                                onChange={e => {
                                    setModalCurrentDamageData({
                                        ...modalCurrentDamageData,
                                        damage: damageSelectedOptions[e.target.value]?.key
                                    })
                                }}
                                disabled={!modalCurrentDamageData?.position}
                            >
                                <option>{Object.keys(deleteDetails).length && modalCurrentDamageData.hasOwnProperty('damage') &&
                                modalCurrentDamageData.damage !== '' ? parseToWord(modalCurrentDamageData.damage) : ' Select Damage'}</option>
                                {damageSelectedOptions &&
                                damageSelectedOptions.map((key, index) => {
                                    return <option value={index} key={key + '_' + index}>{key.value}</option>
                                })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup
                            className="mb-3"
                        >
                            <Input
                                type="textarea"
                                placeholder={'Note about damage'}
                                defaultValue={modalCurrentDamageData?.note}
                                onChange={(e) => {
                                    setModalCurrentDamageData({...modalCurrentDamageData, note: e.target.value})
                                }}
                                rows={5}/>
                        </FormGroup>
                        <FormGroup>
                            <div className={'upload-damage-photo'} onClick={() => inputRef.current.click()}>
                                <IcoMoon
                                    iconSet={IconSet}
                                    icon={'cloud_upload'}
                                    size={25}
                                />
                                <Text color='#ec6409'>Upload

                                </Text>
                            </div>
                            <input style={{display: 'none'}}
                                   onChange={(e) => {
                                       onFilesChange(e)
                                   }}
                                   ref={inputRef}
                                   type="file"
                                   multiple={'multiple'}
                                   name="myImage" accept="image/png, image/gif, image/jpeg"/>
                            <Row className={'mt-3'}>
                                {modalCurrentDamageData?.imagesPreview?.length > 0 ? modalCurrentDamageData?.imagesPreview.map((image, idx) => {
                                    return <Col key={idx + 'par'} className={'mb-3 '}>
                                        <div
                                            onMouseOver={() => {
                                                itemsRef[idx].current.style.display = 'block'
                                            }
                                            }
                                            onMouseLeave={() => {
                                                itemsRef[idx].current.style.display = 'none'
                                            }
                                            }
                                            style={{width: "max-content"}}
                                            className={'position-relative'}>
                                        <img
                                            key={idx + 'img'}
                                            width={80}
                                            height={80}
                                            src={image} alt=""/>
                                            <IcoMoon
                                                iconSet={IconSet}
                                                icon={'trash'}
                                                size={15}
                                                ref={itemsRef[idx]}
                                                style={{display: 'none'}}
                                                onMouseDown={()=>{
                                                    setModalCurrentDamageData({...modalCurrentDamageData,
                                                        images: modalCurrentDamageData?.images.filter((itm,index)=>index !== idx),
                                                        imagesPreview: modalCurrentDamageData?.imagesPreview.filter((itm,index)=>index !== idx),
                                                    })
                                                }}
                                                className={'remove-icon-style'}
                                                key={idx}
                                            />
                                        </div>

                                    </Col>
                                }) : null
                                }
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={()=>{
                        setModalCurrentDamageData({})
                        setDeleteDetails({})
                        toggleDamageModal()
                    }} variant="secondary">
                        Close
                    </Button>
                    {Object.keys(deleteDetails).length && Object.values(deleteDetails).length ?
                        <Button onClick={updateDamage}
                                disabled={!modalCurrentDamageData?.damage}
                                variant="primary">
                            Update Damage
                        </Button>
                        :
                        <Button onClick={saveChanges}
                                disabled={!modalCurrentDamageData?.damage}
                                variant="primary">
                            Save Changes
                        </Button>
                    }
                </ModalFooter>
            </Modal>

            <Modal backdrop={false} className={'modal-dialog-right'} isOpen={showTyreModal} toggle={toggleTyreModal}>
                <ModalHeader closeButton>
                    <div className={'main-color'}>
                        Tyre Wear
                    </div>
                </ModalHeader>
                <div className={'p-3'}>
                    <Form>

                        <div className={'d-flex g-10'}>
                            <img height='30px' width='60px'
                                 src={require(`assets/images/VehicleInspection/front_right.png`)}
                                //  src={require(`assets/images/VehicleInspection/back_right.png`)}
                                 alt=""/>
                            <FormGroup className="mb-3">
                                <Input placeholder={'depth'}
                                       value={wheelDamage?.ns_rear_tyre?.depth}
                                       onChange={(e) => {
                                           selectWheelDamage('ns_rear_tyre', 'depth', e.target.value);
                                       }}
                                />
                                <Input
                                    type="select"
                                    placeholder={'Select Tyre condition'}
                                    style={{width: "100%"}}
                                    className={'cursor-pointer'}
                                    value={wheelDamage.hasOwnProperty('ns_rear_tyre') &&
                                    wheelDamage.ns_rear_tyre.hasOwnProperty('condition') ?
                                        tyreConditions.findIndex(item => item.value === wheelDamage?.ns_rear_tyre?.condition) : null}
                                    onChange={e => {
                                        selectWheelDamage('ns_rear_tyre', 'condition', tyreConditions[e.target.value].value)
                                    }}
                                    disabled={false}
                                >
                                    <option>Select Tyre condition</option>
                                    {tyreConditions &&
                                    tyreConditions.map((key, index) => {
                                        return <option value={index} key={key + '_' + index}>{key.value}</option>
                                    })
                                    }
                                </Input>

                            </FormGroup>
                        </div>
                        <div className={'d-flex g-10'}>
                            <img height='30px' width='60px'
                                 src={require(`assets/images/VehicleInspection/back_right.png`)}
                                 alt=""/>
                            <FormGroup className="mb-3">
                                <Input placeholder={'depth'}
                                       value={wheelDamage?.ns_front_tyre?.depth}
                                       onChange={(e) => {
                                           selectWheelDamage('ns_front_tyre', 'depth', e.target.value);
                                       }}
                                />
                                <Input
                                    type="select"
                                    placeholder={'Select Tyre condition'}
                                    className={'cursor-pointer'}
                                    style={{width: "100%"}}
                                    value={wheelDamage.hasOwnProperty('ns_front_tyre') &&
                                    wheelDamage.ns_front_tyre.hasOwnProperty('condition') ?
                                        tyreConditions.findIndex(item => item.value === wheelDamage?.ns_front_tyre?.condition) : null}
                                    onChange={e => {
                                        selectWheelDamage('ns_front_tyre', 'condition', tyreConditions[e.target.value].value)
                                    }}
                                    disabled={false}
                                >
                                    <option>Select Tyre condition</option>
                                    {tyreConditions &&
                                    tyreConditions.map((key, index) => {
                                        return <option value={index} key={key + '_' + index}>{key.value}</option>
                                    })
                                    }
                                </Input>
                            </FormGroup>
                        </div>
                        <div className={'d-flex g-10'}>
                            <img height='30px' width='60px'
                                 src={require(`assets/images/VehicleInspection/front_left.png`)}
                                //  src={require(`assets/images/VehicleInspection/back_left.png`)}
                                 alt=""/>
                            <FormGroup className="mb-3">

                                <Input placeholder={'depth'}
                                       value={wheelDamage?.os_rear_tyre?.depth}
                                       onChange={(e) => {
                                           selectWheelDamage('os_rear_tyre', 'depth', e.target.value);
                                       }}
                                />
                                <Input
                                    type="select"
                                    placeholder={'Select Tyre condition'}
                                    style={{width: "100%"}}
                                    className={'cursor-pointer'}
                                    value={wheelDamage.hasOwnProperty('os_rear_tyre') &&
                                    wheelDamage.os_rear_tyre.hasOwnProperty('condition') ?
                                        tyreConditions.findIndex(item => item.value === wheelDamage?.os_rear_tyre?.condition) : null}
                                    onChange={e => {
                                        selectWheelDamage('os_rear_tyre', 'condition', tyreConditions[e.target.value].value)
                                    }}
                                    disabled={false}
                                >
                                    <option>Select Tyre condition</option>
                                    {tyreConditions &&
                                    tyreConditions.map((key, index) => {
                                        return <option value={index} key={key + '_' + index}>{key.value}</option>
                                    })
                                    }
                                </Input>
                            </FormGroup>

                        </div>
                        <div className={'d-flex g-10'}>
                            <img height='30px' width='60px'
                                 src={require(`assets/images/VehicleInspection/back_left.png`)}
                                 alt=""/>
                            <FormGroup className="mb-3">

                                <Input placeholder={'depth'}
                                       value={wheelDamage?.os_front_tyre?.depth}
                                       onChange={(e) => {
                                           selectWheelDamage('os_front_tyre', 'depth', e.target.value);
                                       }}
                                />
                                <Input
                                    type="select"
                                    className={'cursor-pointer'}
                                    placeholder={'Select Tyre condition'}
                                    style={{width: "100%"}}
                                    value={wheelDamage.hasOwnProperty('os_front_tyre') &&
                                    wheelDamage.os_front_tyre.hasOwnProperty('condition') ?
                                        tyreConditions.findIndex(item => item.value === wheelDamage?.os_front_tyre?.condition) : null}
                                    onChange={e => {
                                        selectWheelDamage('os_front_tyre', 'condition', tyreConditions[e.target.value].value)
                                    }}
                                    disabled={false}
                                >
                                    <option>Select Tyre condition</option>
                                    {tyreConditions &&
                                    tyreConditions.map((key, index) => {
                                        return <option value={index} key={key + '_' + index}>{key.value}</option>
                                    })
                                    }
                                </Input>
                            </FormGroup>

                        </div>
                        <div className={'d-flex  g-10 mb-3'}>
                            <div className={'d-flex flex-column  g-10'}>
                                <Text>
                                    Spare Tyre
                                </Text>
                                <img height='45px' width='45px'
                                     src={require(`assets/images/VehicleInspection/spare.png`)}
                                     alt=""/>
                            </div>
                            <div className="custom-control custom-radio align-self-end ">
                                <input onChange={() => setWheelDamage({...wheelDamage, spare_tyre: "yes"})}
                                       checked={wheelDamage.spare_tyre === 'yes'}
                                       className={'cursor-pointer mr-2'}
                                       id={'yes'} type="radio"/>
                                <label className={' cursor-pointer'} htmlFor={`yes`}>
                                    Yes
                                </label>
                            </div>
                            <div className="custom-control custom-radio align-self-end ">
                                <input onChange={() => setWheelDamage({...wheelDamage, spare_tyre: "no"})}
                                       checked={wheelDamage.spare_tyre === 'no'}
                                       className={'cursor-pointer mr-2'}
                                       id={'no'} type="radio"/>
                                <label className={' cursor-pointer'} htmlFor={`no`}>
                                    No
                                </label>
                            </div>
                        </div>
                    </Form>
                </div>
                <ModalFooter>
                    <Button onClick={() => {
                        setWheelDamage({spare_tyre: 'yes'})
                        toggleTyreModal()
                    }} variant="secondary">
                        Close
                    </Button>
                    <Button onClick={toggleTyreModal} variant="primary">
                        Save Changes
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle}>
                    <Text
                        bold>{pendingRequest ? 'Generating tasks please wait' : 'Please select the type of document'}</Text>
                    <br/>
                </ModalHeader>
                <ModalBody>
                    {!pendingRequest && <Row>
                        <Col sm={6}>
                            <Button outline={true} className="w-100"
                                    style={{backgroundColor: "white", color: '#ec6409', border: '1px solid #ec6409'}}
                                    type="button" onClick={() => sendData(false)}>Save Vehicle</Button>
                        </Col>
                        <Col sm={6}>
                            <Button className="w-100"
                                    style={{backgroundColor: "#ec6409", color: 'white', border: 'none'}} type="button"
                                    onClick={() => sendData(true)}>Save & Assign Task</Button>
                        </Col>
                    </Row>}
                </ModalBody>

            </Modal>
            <CardsHeader name="Fleets" parentName="Job Management" currentName="Create"/>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert}/>
            </div>
            <Container className="mt--5 admin-main-body" fluid>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Container>
                                    <Row>
                                        <Col md={6} sm={12} xs={12}>
                                            <label className="form-control-label">Please enter the Vehicle Reg.
                                                Number for applying the checklist</label>
                                        </Col>
                                    </Row>
                                    <Row className={'mb-3'}>
                                        <Col md={6} sm={12} xs={12}>
                                            <AsyncSelect
                                                loadOptions={promiseOptions}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                value={filter && filter.regNo ? (regNos.find((element) => element.id === filter.regNo) || '') : ''}
                                                onChange={value => {
                                                    setVehicle(value);
                                                    setMileage(value.current_mileage)
                                                    setFilter({
                                                        ...filter,
                                                        regNo: value.id
                                                    })
                                                }}
                                                placeholder="Search..."
                                                components={{
                                                    DropdownIndicator: () => null,
                                                    IndicatorSeparator: () => null,
                                                }}
                                            />
                                        </Col>
                                        <Col md={3} sm={12} xs={12}>
                                            <Button className="w-100"
                                                    style={{backgroundColor: "#ec6409", color: 'white'}} type="button"
                                                    onClick={() => searchVehicle()}>Search</Button>
                                        </Col>
                                    </Row>
                                    {
                                        vehicle && !loading &&
                                        <VehicleInfo showImage showMileageInput={true} setMileage={setMileage}
                                                     mileage={mileage}
                                                     vehicle={vehicle}/>
                                    }
                                </Container>
                            </CardBody>
                        </Card>


                        <Row>
                            {vehicle && !loading && Object.keys(categories).map((item,index) => {
                                return <React.Fragment key={index}>

                                    <Col md={6}>
                                            <CustomChecklist showRequired={false} title={'Body work'} vehicle={vehicle}
                                                             categoryName={item} category={categories[item]}
                                                             setCategories={changeCategories}/>

                                    </Col>
                                </React.Fragment>
                            })
                            }

                        </Row>

                        {vehicle && !loading &&
                        <>
                            <VehicleDamageComponent
                                damages={damageData}
                                wheelDamageData={wheelDamage}
                                selectTyre={selectTyre}
                                selectDamage={selectDamage}/>

                            {Object.values(damageData).flat().length ? <Table striped>
                                <thead>
                                <tr>
                                    <th>Component</th>
                                    <th>Position</th>
                                    <th>Damage</th>
                                    <th>Note</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(damageData).map((component, index) => {
                                    return damageData[component].map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <th scope="row">{parseToWord(component)}</th>
                                                <td>{parseToWord(item.position)}</td>
                                                <td>{parseToWord(item.damage)}</td>
                                                <td>{parseToWord(item.note)}</td>
                                                <td>
                                                    <IcoMoon
                                                        className={'cursor-pointer'}
                                                        iconSet={IconSet}
                                                        icon={'edit'}
                                                        size={20}
                                                        onClick={()=>editDamage(component,idx)}
                                                    />
                                                    <IcoMoon
                                                        className={'cursor-pointer'}
                                                        iconSet={IconSet}
                                                        icon={'delete_outline'}
                                                        size={20}
                                                        onClick={()=>deleteTempDamage(component,idx)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                })}
                                </tbody>
                            </Table> : null}
                        </>
                        }
                    </Col>
                    {vehicle && !loading &&
                    <>
                       <Col lg={6} sm={12} md={8}>
                           <Row>
                               <img height='25px' width='25px'
                                    src={require(`assets/images/taskEdit/edit.png`)} alt=""/>
                               <TextContainer>
                                   <Text bold>Inspector E-sign:</Text>
                                   <Col lg={4} sm={8} md={5}>
                                       <SignContainer>
                                           <SignatureCanvas
                                               ref={eSign}
                                               canvasProps={{width: window.innerWidth < 732 ? 300 : 500, height: 200, className: 'sigCanvas'}}/>
                                       </SignContainer>
                                   </Col>
                               </TextContainer>
                           </Row>

                       </Col>
                        <Col sm={12}>
                            <Col sm={6}>
                                <Button className="w-50" style={{
                                    backgroundColor: "#fafafa",
                                    color: '#ec6409',
                                    float: 'right',
                                    marginTop: '25px',
                                    opacity: '0.65'
                                }} type="button" onClick={clear}>Clear Signature</Button>
                            </Col>
                        </Col>
                        <Col sm={12}>
                            <Col sm={6}>
                                    <Button className="w-50" disabled={requiredData || !mileage} style={{
                                        backgroundColor: "#ec6409",
                                        color: 'white',
                                        float: 'right',
                                        marginTop: '25px'
                                    }} type="button" onClick={() => checkSignature()}>Save</Button>
                            </Col>
                        </Col>
                    </>

                    }
                </Row>

            </Container>
        </>
    )
}