import React from "react";
import {CustomHeader} from "@pages/new-design-components/custom-components";


export default function BackActionComponent({text, backAction}) {

    return (
        <div className={'d-flex g-5'}>
            <img alt="" className={'cursor-pointer'} width={'24px'} height={'24px'} src={require(`assets/images/ep_back.svg`)} onClick={backAction}/>
            <CustomHeader lineHeight={'none'} fontSize={'16px'} weight={'500'} color={'#000000BF'}>
                {text}
            </CustomHeader>
        </div>
    )
}