import React, {useState} from "react";
const {CustomDropdown, DropdownButton, Dot, ArrowIcon} = require("@pages/new-design-components/custom-components");
const {DropdownMenu, DropdownItem} = require("reactstrap");

export default function CustomDamageDropDown({selectedTitle, handleChange, data = [], border = null, disabled})  {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const selected = data.find(status => status.key === selectedTitle) || {value: 'Select'};

    return ( <CustomDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownButton  className={'w-100'} border={border} disabled={disabled}>
                <div className={'d-flex align-items-center g-5'}>
                    <Dot color={selected.color} /> {selected.value || '-'}
                </div>
                <ArrowIcon
                    isOpen={dropdownOpen}
                    src={require("assets/images/arrow_forward_ios.png")}
                    alt="Dropdown Arrow"
                />
            </DropdownButton>
            <DropdownMenu className={'w-100'}>
                {data.map((title) => (
                    <DropdownItem  key={title.key} onClick={() => {
                        handleChange(title.key)
                        setDropdownOpen(false);
                    }}>
                        <Dot color={title.color} />
                        {title.value}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </CustomDropdown>
    )
}
