import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert'
import axios from 'axios'
import Cookies from 'js-cookie'

import { hasFleetAdminPermission } from '../../../utils/dealer';
import * as constants from 'constants/index.js'


import {
    Container,
    Row,
    Col,
    Card,
    CardBody, Modal,
} from 'reactstrap'

import { parseToWord } from "constants/index.js";
import {ButtonContainer, LoaderContainer, override} from "../reusable-components/styled-components";
import { ClipLoader } from "react-spinners";
import DepartmentsTable from "@pages/components/DepartmentsTable";


export default function VehicleInStock() {
    const { category } = useParams()
    const notificationAlert = useRef(null)
    const history = useHistory()
    const roles = useSelector(state => state.getState).roles;
    const [token, setToken] = useState('')
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState('')
    const [isAdmin, setIsAdmin] = useState('')
    const [showDeleteModal,setShowDeleteModal] = useState(false)
    const [deleteId,setDeleteId] = useState(false)
    const [departmentsFilterObject,setDepartmentsFilterObject] = useState(
        {
            'mechanical': null,
            'valeting': null,
            'body shop' : null,
            'ply lining': null,
            'parts': null,
        }
    )
    const [fleetStatuses] = useState([
        {
            value: 'to_do',
            text: 'To Do'
        },
        {
            value: 'in_progress',
            text: 'In progress'
        },
        {
            value: 'in_review',
            text: 'In review'
        },
        {
            value: 'completed',
            text: 'Completed'
        }
    ]);
    const [selectedFleetStatus, setSelectedFleetStatus] = useState(null)

    const [makesAndModels, setMakesAndModels] = useState('')
    const [models, setModels] = useState([])
    const [makes, setMakes] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataDraft, setTableDataDraft] = useState([])
    const [completedData, setCompletedData] = useState([])
    const [modelValue, setModelValue] = useState('')
    const [makeValue, setMakeValue] = useState('')
    const [activeTab, setActiveTab] = useState('work_needed');
    const [filter, setFilter] = useState({})
    const [workerData, setWorkerData] = useState([])
    const [keys, setKeys] = useState(
        []
    )
    const [defaultKeys] = useState(
        [
            {
                key: 'vehicle_make_name_nested',
                text: 'make'
            },
            {
                key: 'vehicle_model_name_nested',
                text: 'model'
            },
            {
                key: 'stock_no',
                text: 'Reg.Number'
            },
            {
                key: 'colour',
                text: 'Colour'
            },
        ]
    )
    const [workerDataKeys] = useState(
        [
            {
                key: 'index',
                text: 'Order'
            },
            {
                key: 'status',
                text: 'status'
            },
            {
                key: 'stock_no',
                text: 'Reg.Number'
            },
            {
                key: 'vehicle',
                text: 'Vehicle'
            },
            {
                key: 'tasks_count',
                text: 'Task count'
            },
            {
                key: 'toDetails',
                text: 'View'
            },
            {
                key: 'delete',
                text: 'Delete'
            },

        ]
    )


    useEffect(() => {
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        setIsAdmin(hasFleetAdminPermission(roles))
        const source = axios.CancelToken.source()
        let savedFilter = {}
        if (localStorage.getItem('grms_fleet_vehicles')) {
            savedFilter = JSON.parse(localStorage.getItem('grms_fleet_vehicles'))
            setSelectedFleetStatus(savedFilter?.status)
            setFilter(savedFilter)
        }
        const getData = async (source, grs_token) => {
            await fetchData(source, 1, grs_token, savedFilter, false, true)
        }
        if (roles.length) {
            getData(source, grs_token)
        }
        return () => {
            source.cancel()
        }
    }, [roles])

    const notify = (type, header, message) => {
        const options = {
            place: "tc",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title">
                        {" "}
                        {header}
                    </span>
                    <span data-notify="message">
                        <div dangerouslySetInnerHTML={{ __html: message }} />
                    </span>
                </div>
            ),
            type: type,
            icon: "ni ni-bell-55",
            autoDismiss: 5
        }
        notificationAlert.current.notificationAlert(options)
    }

    useEffect(() => {
        if (makes.length && !makeValue && makesAndModels.length) {
            if (filter.hasOwnProperty('make') && filter.make) {
                setMakeValue(makes.find(item => filter.make === item.id)?.name)
                const modelsTemp = makesAndModels.filter(item => item.vehicle_make_id === filter.make)
                setModels(modelsTemp)
                if (modelsTemp.length) {
                    if (filter.hasOwnProperty('model') && filter.model) {
                        setModelValue(modelsTemp.find(item => filter.model === item.id)?.name)
                    }
                }
            }
        }

    }, [makes, makesAndModels])
    const setOrder = async () => {

        let data = { order: [] }
        tableData.forEach(({ id }, index) => {
            data.order.push(
                {
                    "id": id,
                    "position": index
                },
            )
        })

        const orderRequest = await axios({
            method: 'POST',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/order`,
            headers: {
                Authorization: 'Bearer ' + token
            },
            data,
        })
        if (orderRequest?.status === 200) { }
    }
    const fetchData = async (source, pageNumber, token, filter, isSearching = false, firstSearch = false) => {
        //
        localStorage.setItem('grms_fleet_vehicles', JSON.stringify(filter))
        let admin = hasFleetAdminPermission(roles);
        if (!isSearching) {
            const makeRequest = await axios({
                method: 'GET',
                url: constants.FLEET_URL + '/fleet-management/vehicle/makes',
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            setMakes(makeRequest.data.data.makes)
            const modelRequest = await axios({
                method: 'GET',
                url: constants.FLEET_URL + '/fleet-management/vehicle/models',
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            setMakesAndModels(modelRequest.data.data.models)
        }
        let newKeys = []
        let tableKeysData  = []

        if (admin) {
            try {
                // activeTab === 'work_needed' ? tableData : completedData
                if (activeTab === 'work_needed' || activeTab === '' || firstSearch) {
                    const response = await axios({
                        method: 'GET',
                        url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: filter ? filter : '',
                        cancelToken: source.token,
                    })
                    const data = response.data?.data
                    if (data.fleet.length > 0) {
                        tableKeysData = data.fleet[0].departments
                        setTableData(data.fleet)
                        setTableDataDraft(data.fleet)
                    } else {
                        setTableData([])
                        setTableDataDraft([])
                    }

                } if (activeTab === 'work_completed' || activeTab === '' || firstSearch) {
                    const completed = await axios({
                        method: 'GET',
                        url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet?status=completed`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: filter ? filter : '',
                        cancelToken: source.token,
                    })
                    const fleet = completed.data?.data?.fleet
                    if (fleet.length) {
                        setCompletedData(completed.data?.data?.fleet)
                        tableKeysData = completed.data?.data?.fleet[0].departments
                    } else {
                        setCompletedData([])
                    }

                }
                tableKeysData.forEach((item) => {
                    newKeys.push(
                        {
                            key: item.name,
                            text: parseToWord(item.name),
                            filterable: true
                        }
                    )
                })

                newKeys.push({
                    key: 'toDetails',
                    text: 'View'
                },)

                newKeys.push({
                    key: 'delete',
                    text: 'Delete'
                },)
                setKeys([...defaultKeys, ...newKeys])
                setLoading(false)
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
                }
            }
        } else {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${constants.FLEET_MS_API_URL}/fleet-worker/vehicles`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        ...filter,
                        page: pageNumber,
                        category: category ? category : null,
                    },
                    cancelToken: source.token,
                })

                const data = response.data
                setWorkerData(data.data)
                setPagination(data.meta)
                setLoading(false)
                //
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled :: ', error)
                } else {
                    console.error('error :: ', error)
                    notify('warning', 'Warning', error.response ? error.response.statusText : 'Error occurred!')
                }
            }
        }

    }
    const goToDetails = (id) => {
        history.push(`/admin/vehicle-details?id=${id}`)
    }
    const addVehicle = () => {
        history.push({
            pathname: '/admin/fleet-job/create'
        })
    }

    const removeItem = async (id) => {
        try {
            const orderRequest = await axios({
                method: 'DELETE',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet/${id}`,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            if (orderRequest?.status === 200) {
                setDeleteId(null)
            }
        } catch (e) {
            setDeleteId(null)
        }
    }

    const filterVehicle = async (tempFilter = null,firstSearch = true) => {
        const source = axios.CancelToken.source()
        await fetchData(source, 1, token, tempFilter ? tempFilter : filter, true, firstSearch)
    }

    const setMakeFilter = async (e) => {
        setModels(makesAndModels.filter(item => item.vehicle_make_id === e.id))
        setMakeValue(e.name)
        setModelValue('')
        setFilter({
            ...filter,
            make: e.id,
            model: null,
        })
        let tempFilter = filter
        tempFilter.make = e.id
        tempFilter.model = null
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setFleetStatusFilter = async (e) => {
        let tempFilter = filter
        tempFilter.status = e.value
        tempFilter.model = null
        setSelectedFleetStatus(e.text)
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const setModelFilter = async (e) => {
        setModelValue(e.name)
        let tempFilter = filter
        tempFilter.model = e.id
        setFilter(tempFilter)
        await filterVehicle(tempFilter)
    }
    const sortItems = (data) => {
        if (activeTab === 'work_needed') {
            setTableData(data)
        }

    }
    const searchByText = async (e) => {
        let tempFilter = filter
        tempFilter['search'] = e.target.value
        setFilter(tempFilter)
        await filterVehicle(tempFilter,true)
    }
    const clearFilers = () => {
        setMakeValue(null)
        setModelValue(null)
        setSelectedFleetStatus(null)
        localStorage.removeItem('grms_fleet_vehicles')
        setFilter({})
        filterVehicle({})
    }
    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }
    const filterData = (obj) => {
        setDepartmentsFilterObject(obj)
        setTableData(filterDataByStatuses(tableDataDraft,obj))
    }
    const filterDataByStatuses = (data, filter) => {
        return data.filter(item => {
            for (const department of item.departments) {
                const filterStatus = filter[department.name];
                if (filterStatus !== null && filterStatus !== department.status) {
                    return false;
                }
            }
            return true;
        });
    };

    return (
        <>
            <Modal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                className="modal-dialog-centered"
            >
                <div className={'p-3'}>
                    <h6 className="modal-title main-color">Are you sure you want to delete vehicle?</h6>
                </div>

                <div className="modal-footer justify-content-end">
                    <ButtonContainer
                        onClick={() => {
                            setShowDeleteModal(false)
                            setDeleteId(null)
                        }}
                    >
                        {`Close`}
                    </ButtonContainer>
                    <ButtonContainer
                        reverse
                        onClick={() => {
                            removeItem(deleteId)
                            setShowDeleteModal(false)
                        }}
                    >
                        {`Delete`}
                    </ButtonContainer>

                </div>
            </Modal>
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlert} />
            </div>
            {loading ?
                <LoaderContainer>
                    <ClipLoader
                        css={override}
                        size={40}
                        color={`#7B61E4`}
                        loading={loading}
                    />
                </LoaderContainer> :
                <Container className="admin-main-body" fluid>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DepartmentsTable
                                        pagination={pagination}
                                        activeTab={activeTab}
                                        departmentsFilterObject={departmentsFilterObject}
                                        setDepartmentsFilterObject={filterData}
                                        setActiveTab={setActiveTab}
                                        workerValues={workerData}
                                        isDepartments={true}
                                        searchValue={filter?.hasOwnProperty('search') ? filter['search'] : ''}
                                        showClearButton={true}
                                        clearFilers={clearFilers}
                                        noPriority={false}
                                        isAdmin={isAdmin}
                                        addNew={() => addVehicle()}
                                        searchPlaceHolder={'Reg No'}
                                        onSearchChange={e => searchByText(e)} e
                                        make={makes}
                                        fleetStatuses={fleetStatuses}
                                        showFleetStatus={true}
                                        selectFleetStatus={setFleetStatusFilter}
                                        selectedFleetStatus={selectedFleetStatus}
                                        model={models}
                                        labelKey={`name`}
                                        valueKey={`id`}
                                        selectMake={e => {
                                            setMakeFilter(e)
                                        }}

                                        selectModel={e => {
                                            setModelFilter(e)
                                        }}
                                        setData={(data) => {
                                            sortItems(data)
                                        }}
                                        setOrder={setOrder}
                                        makeValue={makeValue ? makeValue : ''}
                                        modelValue={modelValue ? modelValue : ''}
                                        tableData={activeTab === 'work_needed' ? tableData : completedData}
                                        goToDetails={goToDetails}
                                        removeItem={(id)=>{
                                            setDeleteId(id)
                                            setShowDeleteModal(true)
                                        }}
                                        keys={isAdmin ? keys : workerDataKeys}
                                        paginate={pageNumber => fetchData(axios.CancelToken.source(), pageNumber, token, filter)} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}