import React, {useState} from 'react'
import {Table, PopoverItem, UncontrolledPopover, PopoverHeader, PopoverBody, ButtonGroup, Button} from "reactstrap";
import {
    CustomBadge, CustomHeader,
    CustomTableRow,
    CustomTD, OptionContainer, OptionsContainer, StatusSpan,
    StyledTable,
    StyledTableHeader,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import {parseToWord} from "../../../constants";
import ThreeDots from '../../../assets/images/mi_options-vertical.svg';



const QuotesTable = ({keys, tableData, goToDetails, deleteContent, withoutDelete = false, quickView, showQuickView = true}) => {

    const [popoverOpenState, setPopoverOpenState] = useState({});

    const togglePopover = (index) => {
        setPopoverOpenState((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const generateTableItem = (fleetTask,index) => {
        return keys.map((key) => {
            if (key.key === 'status') {
                return (
                    <CustomTD key={key.key}>
                        <StatusSpan type={fleetTask[key.key]}>
                            {fleetTask[key.key] ? parseToWord(fleetTask[key.key]) : 'N/A'}
                        </StatusSpan>

                    </CustomTD>
                );
            } else if (key.key === 'options') {
                return (
                    <CustomTD key={key.key} className={'cursor-pointer'}>
                        <img src={ThreeDots} id={`PopoverLegacy_${index}`} />
                        <UncontrolledPopover
                            placement="left"
                            className="fleet-popover"
                            target={`PopoverLegacy_${index}`}
                            trigger="legacy"
                            isOpen={popoverOpenState[index]}
                            toggle={()=>{togglePopover(index)}}
                        >
                            <OptionsContainer className={'cursor-pointer'}>
                                {
                                    showQuickView &&
                                    <OptionContainer
                                        onClick={()=>{
                                            quickView(index)
                                            togglePopover(index)
                                        }}
                                    >
                                        <CustomHeader  fontSize={'14px'} weight={'400'}>
                                            Quick View
                                        </CustomHeader>

                                    </OptionContainer>
                                }
                                <OptionContainer
                                    onClick={()=>{
                                        goToDetails(index)
                                    }}
                                >
                                    <CustomHeader  fontSize={'14px'} weight={'400'}>
                                          Edit
                                    </CustomHeader>

                                </OptionContainer>

                                {!withoutDelete &&  <OptionContainer
                                    onClick={() =>  deleteContent(index)}
                                >
                                    <CustomHeader fontSize={'14px'} weight={'400'}>
                                        Delete
                                    </CustomHeader>
                                </OptionContainer>}


                            </OptionsContainer>
                        </UncontrolledPopover>
                    </CustomTD>
                );
            } else if (key.key === 'stock_no' || key.key === 'vehicle_make' || key.key === 'vehicle_model') {
                const vehicle = fleetTask.vehicle;
                return (
                    <CustomTD key={key.key}>
                        {`${vehicle[key.key].name || vehicle[key.key] || 'N/A'}`}
                    </CustomTD>
                );
            } else if (key.key === 'view') {
                return (
                    <CustomTD color={'#3F8CFF'} onClick={()=>{
                        quickView(index)
                        togglePopover(index)
                    }}
                              className={'cursor-pointer'}
                              width={'20%'}>
                        View
                    </CustomTD>
                );
            }
            return (
                <CustomTD key={key.key}>
                    {fleetTask[key.key] ? fleetTask[key.key].toString() : 'N/A'}
                </CustomTD>
            );
        });
    };
    return (
        <div className={'table-container'}>
            <StyledTable className={'table-borderless'}>
                <StyledTableHeader className={'header-white-text'}>
                    <tr>
                        {keys.map((key)=>{
                            return <StyledTableHeaderCell className={'border-bottom-0'} key={key.key}>
                                {
                                    key.text
                                }
                            </StyledTableHeaderCell>
                        })}

                    </tr>
                </StyledTableHeader>
                <tbody>
                {
                    tableData.map((fleetTask,index) =>{
                        return  <CustomTableRow key={index}>
                            {
                                generateTableItem(fleetTask,index)
                            }

                        </CustomTableRow>
                    })
                }


                </tbody>
            </StyledTable>
        </div>
    )
}
export default QuotesTable;