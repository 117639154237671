import React, {useEffect, useRef, useState} from 'react'
import axios from "axios";
import * as constants from "../../../constants";
import Cookies from "js-cookie";
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
    CustomHeader,
    FlexInnerComponent,
} from "@pages/new-design-components/custom-components";
import {departments, handleFocus, parseToWord} from "../../../constants";
import ThreeDots from "@assets/images/mi_options-vertical.svg";
import {Button, ButtonGroup, Modal, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";
import CustomSearch from "@pages/components/CustomSearch";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import {DELETE_FLEET_REQUEST} from "@redux/Fleet/actions";
import fleetState from "@redux/Fleet/reducer";
import usePrevious from "../../../hooks/useprevious";
import {useHistory} from "react-router-dom";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";
import JobsTable from "@pages/new-design-components/JobsTable";
import QuotesTable from "@pages/new-design-components/QuotesTable";
import {ClipLoader} from "react-spinners";
import {override} from "@pages/reusable-components/styled-components";
import NotificationAlert from "react-notification-alert";

export default function  RepairQuote() {
    const history = useHistory();
    const [token, setToken] = useState('');
    const [filter, setFilter] = useState({})
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(true)
    const notificationAlert = useRef(null)

    const keys =
        [

            {
                key: 'vehicle_make',
                text: 'Manufacturer'
            },
            {
                key: 'vehicle_model',
                text: 'Model'
            },
            {
                key: 'stock_no',
                text: 'Reg. No.'
            },
            {
                key: 'status',
                text: 'Status',
            },
            {
                key: 'options',
                text: ''
            },
        ];
    const fetchData =  (token,page = 1,filter = {}) => {
             axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quotes`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: filter ? filter : '',
            }).then(response=>{
                 const data = response.data?.data
                 setTableData(data)
                 setLoading(false)
             }).catch(()=> {
                 setLoading(false)
             })
    }

    const goToDetails =  (index) => {
        const data = tableData[index]
        history.push({
            pathname: `/admin/vehicle-quote-preview`,
            state: {
                vehicleId: data.vehicle_id,
                fleetId: data.fleet_id,
                quoteId: data.id
            }
        })
    }

    useEffect(()=>{
        const grs_token = Cookies.get('grs_token')
        setToken(grs_token)
        fetchData(grs_token)
    },[])

    const searchData = async (key,value) => {
        const tempFilter = {...filter}
        tempFilter[key] = value
        setFilter(tempFilter)
        await fetchData(token,1,tempFilter)
    }
    return (
        loading ?

            <ClipLoader
                css={override}
                size={40}
                color={`#7B61E4`}
                loading={loading}
            />

            :

           <div className={'container-fluid mt-3'}>
               <div className="rna-wrapper">
                   <NotificationAlert ref={notificationAlert}/>
               </div>

            <CustomHeader className={'mb-5'}>
                Vehicles
            </CustomHeader>
            <div className={'d-flex g-10'}>
                <FlexInnerComponent width={33}>
                    <CustomSearch
                        onChange={(e) => {
                            searchData('search', e.target.value)
                        }}
                        placeholder="Search"
                    />
                </FlexInnerComponent>
                <FlexInnerComponent width={20} >
                    <CustomDropdownComponent
                        border={'none'}
                        selectedTitle={filter?.inspectionStatus}
                        statusArrayKey={'jobListStatuses'}
                        handleChange={(data)=>{
                            // searchData('inspectionStatus', data)
                        }}
                    />
                </FlexInnerComponent>
            </div>
            <QuotesTable
                keys={keys}
                showQuickView={false}
                goToDetails={goToDetails}
                withoutDelete={true}
                tableData={tableData}
            />
        </div>
    )
}