import React, {useState} from "react";
import {CustomBadge} from "@pages/new-design-components/custom-components";
const {CustomDropdown, DropdownButton, Dot, ArrowIcon} = require("@pages/new-design-components/custom-components");
const {DropdownMenu, DropdownItem} = require("reactstrap");
const {fleetNewStatuses} = require("../../../../constants");

export default function CustomDropdownComponent({selectedTitle,
                                                    handleChange,
                                                    statusArrayKey = 'interiorStatuses',
                                                    border = null,
                                                    maxWidth = null,
                                                    withoutDot = false,
                                                    noBadge = false,
})  {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const selected = fleetNewStatuses[statusArrayKey].find(status => status.key === selectedTitle) || {};

    return ( <CustomDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownButton maxWidth={maxWidth} border={border}>
            {
                withoutDot ? (
                        noBadge ? <span>{selected.title || '-'}</span> : <CustomBadge type={selected.key}   active>
                            {selected.title || '-'}
                        </CustomBadge>
                    )
                    :
                    <div className={'d-flex align-items-center g-5'}>
                        <Dot color={selected.color} /> {selected.title || '-'}
                    </div>
            }

            <ArrowIcon
                isOpen={dropdownOpen}
                src={require("assets/images/arrow_forward_ios.png")}
                alt="Dropdown Arrow"
            />
        </DropdownButton>
        <DropdownMenu className={'w-100'}>
            {fleetNewStatuses[statusArrayKey].map((title) => (
                <DropdownItem key={title.key} onClick={() => {
                    handleChange(title.key)
                    setDropdownOpen(false);
                }}>
                    <Dot color={title.color} />
                    {title.title}
                </DropdownItem>
            ))}
        </DropdownMenu>
    </CustomDropdown>
  )
}
