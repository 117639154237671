import React, {createRef, useEffect, useMemo, useRef, useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import * as constants from "../../../constants";
import cloneDeep from 'lodash/cloneDeep';
import {
    componentOptions,
    convertMinutesToHoursAndMinutes, damageOptions,
    damagesPosition,
    parseToWord,
    tyreConditions
} from "../../../constants";
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter, ModalHeader,
    Row
} from "reactstrap";
import JobCard from "@pages/components/JobDetailsComponents/JobCard";
import {
    AddImageButton,
    CustomHeader, CustomInput, CustomTD,
    DamageIconContainer, FleetButton,
    SignatureContainer
} from "@pages/new-design-components/custom-components";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import {CustomSignatureCanvas} from "@pages/customers/customer/signatureExperience";
import {ButtonContainer, SignContainer, Text} from "@pages/reusable-components/styled-components";
import SignatureCanvas from "react-signature-canvas";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import FleetBodyWorkDamageComponent from "@pages/fleet-management/FleetBodyWorkDamageComponent";
import VehicleDamageComponent from "@pages/components/VehicleDamageComponent";
import CustomDamageDropDown from "@pages/components/JobDetailsComponents/CustomDropDown";
import axios from "axios";
import RequiredInspectionList from "@pages/fleet-management/RequiredInspectionList";
import {BodyworkDamageFrom, TyreDamagesForm} from "@pages/fleet-management/BodyworkComponents";

export default function  FleetVehicleDetails() {
    const location = useLocation();
    const history = useHistory();
    const eSign = useRef(null);
    const inputRef = useRef()
    const { vehicleId, fleetId } = location.state || {};
    const [loading,setLoading] = useState(true);
    const [showDamageModal,setShowDamageModal] = useState(false);
    const [data,setData] = useState([]);
    const [signature,setSignature] = useState({});
    const [wheelDamage, setWheelDamage] = useState();
    const [wheelDamageOriginal, setWheelDamageOriginal] = useState();
    const [damageData, setDamageData] = useState({})
    const [damageDataDraft, setDamageDataDraft] = useState({})
    const [showTyreModal, setShowTyreModal] = useState(false)
    const [vehicleDamageModal, setVehicleDamageModal] = useState(false)
    const [modalCurrentDamageData, setModalCurrentDamageData] = useState({})
    const [damageModalHeaderText, setDamageModalHeaderText] = useState(null)
    const [damageComponent, setDamageComponent] = useState(null)
    const [updatingDamageIndex, setUpdatingDamageIndex] = useState(null)
    const [damageSelectedOptions, setDamageSelectedOptions] = useState([])
    const [requiredInspectionList, setRequiredInspectionList] = useState([])
    const [deleteIds, setDeleteIds] = useState([])
    const toggleTyreModal = () => setShowTyreModal(!showTyreModal);
    const toggleVehicleDamageModal = () => setVehicleDamageModal(!vehicleDamageModal);
    const open = () => {
        setWheelDamage({...wheelDamageOriginal})
        toggleVehicleDamageModal()
    }
    const openDamageModal = (key,index) => {
      const activeDamage = damageData[key][index]
      setDamageSelectedOptions(damageOptions[key] ? damageOptions[key] : damageOptions.body)
      setUpdatingDamageIndex(index)
      setDamageComponent(key)
      setModalCurrentDamageData(activeDamage)
      toggleVehicleDamageModal()
      setShowDamageModal(true)
    }
    const openTyreModal = () => {
        toggleVehicleDamageModal()
        setDamageComponent('tyre_wear')
        selectTyre();
    }
    const changeRequiredData = ({index, idx, value}) => {
        let tempData = [...requiredInspectionList];
        if (tempData[index]?.list?.[idx]) {
            tempData[index].list[idx].required = value;
        }
        setRequiredInspectionList(tempData);
    }
    const deleteDamage = (key,index) => {
        const updatedDamageData = { ...damageData };
        if (updatedDamageData[key] && updatedDamageData[key][index]) {
            if (updatedDamageData[key][index].id) {
                setDeleteIds([...deleteIds,updatedDamageData[key][index].id])
            }
            updatedDamageData[key].splice(index, 1);
        }
        setDamageData(updatedDamageData);
    }

    const fetchData = () => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle`,
            params: {
                vehicleId,
                fleetId,
            }
        }).then(res=>{
            const tempData = res.data.data
            setData(tempData)
            let tempObj = {}
            tempData.damages.forEach(damage=>{
                let index = null
                index = Object.values(damagesPosition).findIndex(item=>item === damage.type)
                if (index !== null) {
                    const key = Object.keys(damagesPosition)[index]
                    tempObj[key] = []
                    tempObj[key].push(damage)
                }
            })
            if (tempData.signature) {
                setSignature(tempData.signature)
            }
            setDamageData(cloneDeep(tempObj))
            setDamageDataDraft(cloneDeep(tempObj))
            const output = tempData.checkTyreDamages.reduce((acc, item) => {
                acc[item.tyre] = {
                    depth: item.depth,
                    position: item.tyre,
                    condition: item.condition
                };
                return acc;
            }, {});
            setWheelDamage(cloneDeep(output))
            setWheelDamageOriginal(cloneDeep(output))
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/get-required-inspection`,
            }).then(resource=>{
               let requiredData = resource.data.data;
                requiredData = requiredData.map(item => {
                    item.list = item.list.map(listItem => {
                        tempData.requiredInspectionResult.map(res=>{
                            if (res?.required_inspection_id === listItem.id) {
                                listItem.option_id = res.id;
                                listItem.required = res?.required === 1;
                            }
                        })
                        return listItem;
                    });
                    return item;
                });
                setRequiredInspectionList(requiredData)
                setLoading(false)
            }).catch(err=>{
                setLoading(false)
            })
        }).catch(err=>{
            setLoading(false)
        })
    }
    const redirectToManagement = () => {
        history.push({
            pathname: '/admin/fleet-vehicles-list'
        })
    }
    useEffect(()=>{
        if (vehicleId && fleetId) {
            fetchData()
        }
    },[vehicleId, fleetId])

    const clear = () => {
        eSign.current.clear()
    }

    const selectWheelDamage = (tyre, tyre_damage_type, val) => {
        let tempDamage = {...wheelDamage};
        if (!Object.keys(tempDamage).includes(tyre)) {
            tempDamage[tyre] = {};
        }
        tempDamage[tyre][tyre_damage_type] = val;
        tempDamage[tyre]['position'] = tyre;
        setWheelDamage(tempDamage);
    };

    const saveChanges = () => {
        let tempData = {...damageData};
        let modalTempData = {...modalCurrentDamageData};
        modalTempData.type = damageModalHeaderText;
        if (!tempData[damageComponent]) {
            tempData[damageComponent] = []
        }
        if (updatingDamageIndex !== null) {
            tempData[damageComponent][updatingDamageIndex] = modalTempData
            setUpdatingDamageIndex(null)
        } else {
            tempData[damageComponent].push(modalTempData)
        }
        setDamageData(tempData)
        setModalCurrentDamageData({})
        toggleVehicleDamageModal()
    }
    const sendData = async () => {

        try {
            const mergedList = requiredInspectionList.reduce((acc, item) => {
                return acc.concat(item.list);
            }, []);
            await axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/update-required-inspection`,
                data: {
                    data: mergedList,
                    vehicleId,
                    fleetId,
                },
            });
        } catch (error) {
            console.error('Error updating require inspection:', error);
        }
        wheelDamageOriginal.fleetId = fleetId;
        wheelDamageOriginal.vehicleId = vehicleId;
        try {
            await axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-tyre-damage`,
                data: wheelDamageOriginal,
            });
        } catch (error) {
            console.error('Error sending wheel damage data:', error);
        }
        if(eSign.current !== null) {
            try {
                const canvas = eSign.current.getTrimmedCanvas();
                canvas.toBlob(async (blob) => {
                    const formDataSignature = new FormData();
                    formDataSignature.append('fleet_id', fleetId);
                    formDataSignature.append('vehicle_id', vehicleId);
                    formDataSignature.append('e_assign', blob, 'signature.png');

                    const res = await axios({
                        method: 'POST',
                        url: `${constants.FLEET_MS_API_URL}/fleet-management/sign-inspection`,
                        data: formDataSignature,
                    });

                    if (res) {
                        setDeleteIds([]);
                    }
                }, 'image/png');

            } catch (error) {
                console.error('Error sending wheel damage data:', error);
            }
        }
        if (deleteIds.length) {
            try {
               const res = await axios({
                    method: 'POST',
                    url: `${constants.FLEET_MS_API_URL}/fleet-management/damages-delete`,
                    data: deleteIds,
                });
               if (res) {
                   setDeleteIds([])
               }
            } catch (error) {
                console.error('Error sending wheel damage data:', error);
            }
        }
        let currentDamages = Object.values(damageData).flat();
        let draftDamages = Object.values(damageDataDraft).flat();
        let newDamages = currentDamages.filter(currentDamage => !currentDamage.id);
        let updatedDamages = currentDamages.filter(currentDamage => {
            return draftDamages.some(draftDamage => {
                return draftDamage.id === currentDamage.id &&
                    JSON.stringify(draftDamage) !== JSON.stringify(currentDamage);
            });
        });
        let tempDamageData = [...newDamages,...updatedDamages];

        const damageRequests = tempDamageData.map((item) => {
            let formData = new FormData();
            formData.append('damage', item.damage);
            formData.append('position', item.position);
            formData.append('note', item.note);
            formData.append('type', item.type);
            formData.append('fleetId', fleetId);
            formData.append('vehicleId', vehicleId);
            if (item.id) {
                formData.append('id', item.id);
            }
            if (item?.images?.length > 0) {
                let index = 0;
                item.images.forEach((image) => {
                    if (!image.hasOwnProperty('image_link')) {
                        formData.append(`files[${index}]`, image);
                        index++;
                    }
                });
            }
            return axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle-damage`,
                data: formData,
            });
        });

        try {
            await Promise.all(damageRequests);
            await fetchData()
            console.log('All damage data sent successfully');
        } catch (error) {
            console.error('Error sending damage data:', error);
        }
    };
    const onFilesChange = (e) => {
        let images = []
        let imagesPreview = []
        if (modalCurrentDamageData?.images?.length) {
            images = [...modalCurrentDamageData.images]
        }
        if (modalCurrentDamageData?.imagesPreview?.length) {
            imagesPreview = [...modalCurrentDamageData.imagesPreview]
        }

        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            images.push(file)
            let reader = new FileReader();
            reader.readAsDataURL(file)
            imagesPreview.push(URL.createObjectURL(file))
        }
        setModalCurrentDamageData({...modalCurrentDamageData, images: images, imagesPreview: imagesPreview})
    }
    const selectDamage = (damage) => {
        setDamageModalHeaderText(damagesPosition[damage])
        setDamageComponent(damage)
        setDamageSelectedOptions(damageOptions[damage] ? damageOptions[damage] : damageOptions.body)
        let data = {
            position: '',
            damage: '',
            note: '',
            images: [],
            imagesPreview: []
        }
        setModalCurrentDamageData({...data})
       if (!showDamageModal) {
           setShowDamageModal(true)
       }
    }

    const selectTyre = () => {
        if (showDamageModal) {
            setShowDamageModal(false)
        }
        setShowTyreModal(true)
    }
    const checkBorder = (position) => (position === damageComponent)

    return (
        loading ? <></>
            :
            <div className={'container-fluid mt-3'}>
                <Modal
                    className={'modal-dialog-right'}
                    isOpen={vehicleDamageModal}
                    toggle={toggleVehicleDamageModal}
                    backdrop="static"
                >
                        <div>
                            <CardHeader>
                                <div className={'d-flex justify-content-between'}>
                                    <CustomHeader weight={'600'} fontSize={'14px'}>
                                        Record Damage
                                    </CustomHeader>
                                    <CustomHeader color={'red'} className={'cursor-pointer'} onClick={toggleVehicleDamageModal} weight={'600'} fontSize={'14px'}>
                                        Close
                                    </CustomHeader>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <CustomHeader weight={'700'} fontSize={'14px'}>
                                    Area
                                </CustomHeader>
                                <div>
                                    <div className={'d-flex justify-content-around g-5 mb-3'}>
                                        <DamageIconContainer active={checkBorder('front')}>
                                            <img onClick={() => selectDamage('front')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3' }
                                                 src={require("assets/images/VehicleInspection/car_front.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                        <DamageIconContainer active={checkBorder('left')}>
                                            <img onClick={() => selectDamage('left')}
                                                 alt={''}
                                                 className={'cursor-pointer'}
                                                 src={require("assets/images/VehicleInspection/car_left.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                        <DamageIconContainer active={checkBorder('top')}>
                                            <img onClick={() => selectDamage('top')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3'}
                                                 src={require("assets/images/VehicleInspection/car_top.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                        <DamageIconContainer active={checkBorder('doors')}>
                                            <img onClick={() => selectDamage('doors')}
                                                 alt={''}
                                                 className={'cursor-pointer'}
                                                 src={require("assets/images/VehicleInspection/car_doors.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                    </div>
                                    <div className={'d-flex justify-content-around g-5'}>
                                        <DamageIconContainer active={checkBorder('back')}>
                                            <img onClick={() => selectDamage('back')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3'}
                                                 src={require("assets/images/VehicleInspection/car_back.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                        <DamageIconContainer active={checkBorder('right')}>
                                            <img onClick={() => selectDamage('right')}
                                                 alt={''}
                                                 className={'cursor-pointer'}
                                                 src={require("assets/images/VehicleInspection/car_right.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                        <DamageIconContainer active={checkBorder('inside')}>
                                            <img onClick={() => selectDamage('inside')}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3'}
                                                 src={require("assets/images/VehicleInspection/car_inside.png")}
                                                 width={72}
                                                 height={72}/>

                                        </DamageIconContainer>
                                        <DamageIconContainer  active={checkBorder('tyre_wear')}>
                                            <img onClick={() => {
                                                   setDamageComponent('tyre_wear')
                                                   selectTyre();
                                                 }}
                                                 alt={''}
                                                 className={'cursor-pointer mb-3'}
                                                 src={require("assets/images/VehicleInspection/tyre.png")}
                                                 width={72}
                                                 height={72}/>
                                        </DamageIconContainer>
                                    </div>
                                </div>
                                {showDamageModal && <div>
                                    <BodyworkDamageFrom
                                        damageComponent={damageComponent}
                                        modalCurrentDamageData={modalCurrentDamageData}
                                        setModalCurrentDamageData={setModalCurrentDamageData}
                                        damageSelectedOptions={damageSelectedOptions}
                                        onFilesChange={onFilesChange}
                                        inputRef={inputRef}
                                    />
                                    <CardFooter>
                                        <Button onClick={() => {
                                            setDamageSelectedOptions([])
                                            setDamageComponent(null)
                                            setModalCurrentDamageData({})
                                            setShowDamageModal(false)
                                            toggleVehicleDamageModal()
                                            if (updatingDamageIndex >= 0) {
                                                setUpdatingDamageIndex(null)
                                            }
                                        }} variant="secondary">
                                            Close
                                        </Button>
                                        <Button onClick={saveChanges}
                                                disabled={!modalCurrentDamageData?.damage}
                                                variant="primary">
                                            Save Changes
                                        </Button>
                                    </CardFooter>
                                </div>}
                                {  showTyreModal &&
                                        <div className={'mt-3'}>
                                            <TyreDamagesForm selectWheelDamage={selectWheelDamage} wheelDamage={wheelDamage}/>
                                            <CardFooter>
                                                <ModalFooter>
                                                    <Button onClick={() => {
                                                        toggleTyreModal()
                                                        setDamageSelectedOptions([])
                                                        setDamageComponent(null)
                                                        setModalCurrentDamageData({})
                                                        setShowDamageModal(false)
                                                        toggleVehicleDamageModal()
                                                    }} variant="secondary">
                                                        Close
                                                    </Button>
                                                    <Button onClick={()=>{
                                                        setWheelDamageOriginal({...wheelDamage})
                                                        toggleTyreModal();
                                                    }} variant="primary">
                                                        Save Changes
                                                    </Button>
                                                </ModalFooter>
                                            </CardFooter>
                                        </div>
                                }
                            </CardBody>

                        </div>

                </Modal>
                <BackActionComponent backAction={redirectToManagement} text={'Back to Vehicles List'}/>
                <div className={'d-flex justify-content-between'}>
                    <CustomHeader lineHeight={'unset'} >Vehicle Check In</CustomHeader>
                    <FleetButton
                        height={'40px'}
                        width={'164px'}
                        backgroundColor={'#F3994A'}
                        radius={'8px'}
                        onClick={sendData}
                    >
                        Save
                    </FleetButton>
                </div>
                <Row className={'mt-3'}>
                    <Col lg={3}>
                        <JobCard workerDetails={false} data={data} saveVehicleDetails={()=>{}}/>
                        <Card className={'fleet-details-container'}>
                            <CardHeader className={'border-bottom-0 pb-0'}>
                                <div className={'d-flex g-5'}>
                                    <CustomHeader fontSize={'16px'}>
                                       Manager E-signature

                                    </CustomHeader>
                                </div>
                                <div className={'d-flex justify-content-between align-items-center'}>
                                    <CustomHeader weight={'400'} fontSize={'14px'}>
                                        Inspector Name
                                    </CustomHeader>
                                    {
                                        ( signature && signature.image_link) ? null :
                                            <IcoMoon
                                                className={'cursor-pointer'}
                                                iconSet={IconSet}
                                                icon={'ic_spinner'}
                                                size={16}
                                                onClick={clear}
                                            />
                                    }

                                </div>
                            </CardHeader>
                            <CardBody>
                                <SignatureContainer>
                                    {
                                        ( signature && signature.image_link) ?
                                            <img height={200} width={'100%'} src={signature.image_link} alt=""/>
                                            :
                                            <SignatureCanvas
                                                ref={eSign}
                                                canvasProps={{height: 200, className: 'sigCanvas'}}/>
                                    }

                                </SignatureContainer>
                            </CardBody>
                         </Card>
                    </Col>
                    <Col lg={9}>
                        <Row>
                            <RequiredInspectionList data={requiredInspectionList} changeData={changeRequiredData}/>
                        </Row>
                        <Col lg={12}>
                            <FleetBodyWorkDamageComponent
                                showDamageModal={open}
                                openDamageModal={openDamageModal}
                                data={damageData}
                                tyreData={wheelDamageOriginal}
                                openTyreModal={openTyreModal}
                                deleteRow={deleteDamage}/>
                        </Col>

                    </Col>
                </Row>
            </div>
    )
}