import React, {Fragment} from 'react'
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {
    PdfHeader, CustomTD,
    StyledTable,
    StyledTableHeaderCell, InspectionReportItemsContainer, FlexInnerComponent, Dot
} from "@pages/new-design-components/custom-components";
import {CURRENCY, getToday, parseToWord} from "../../../../constants";
import axios from "axios";

export default function InspectionReportComponentForPrint({ vehicle, parsable= false, inspectionData}) {
    return (
        <Card id="pdf-content" className={"table-container " + parsable ? "position-absolute" : ""}
              style={{ width: '900px', minHeight: '1520px', overflow: 'hidden', bottom: parsable ? '200%' : 'unset'}}>
            <CardHeader>
                <PdfHeader className={'text-center'} lineHeight={'none'} fontSize={'24px'} weight={'700'} color={'#000000'}>
                    Inspection report
                </PdfHeader>
            </CardHeader>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'20px'}
                        weight={'700'}
                        className={'text-center mb-0'}>
                        CUSTOMER INFORMATION
                    </PdfHeader>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Name
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.first_name}   {vehicle.last_name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Location
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'9px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.address}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    borderRadius={'0 0 0px 16px'}
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Phone
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.mobile_number}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Email
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    borderRadius={'0 0 16px 0px'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.email}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                </InspectionReportItemsContainer>
            </CardBody>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'20px'}
                        weight={'700'}
                        className={'text-center mb-0'}>
                        SERVICE PROVIDER
                    </PdfHeader>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Name
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    GRS Motor Group
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Location
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'9px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    City Name, Country 00010
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    borderRadius={'0 0 0px 16px'}
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Phone
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    +1 (000)000-00-00
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Email
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    borderRadius={'0 0 16px 0px'}
                                    lineHeight={'none'} fontSize={'14px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    grs@sample.com
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                </InspectionReportItemsContainer>
            </CardBody>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'20px'}
                        weight={'700'}
                        className={'text-center mb-0'}>
                        VEHICLE INFORMATION
                    </PdfHeader>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'50'} className={'d-flex'}>
                            <FlexInnerComponent width={'30'}>
                                <PdfHeader
                                    backgroundColor={'#000'} color={'#fff'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Inspection date
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'70'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {getToday()}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Reg no
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.stock_no}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Make
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.make.name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Model
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.model.name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                    <div className={'d-flex'}>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    borderRadius={'0 0 0px 16px'}
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Mileage
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle.current_mileage}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Engine Size
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {(vehicle?.features.engine_capacity / 1000).toFixed(1)}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                        <FlexInnerComponent width={'33'} className={'d-flex'}>
                            <FlexInnerComponent width={'45'}>
                                <PdfHeader
                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'600'} className={'text-center  mb-0'}>
                                    Fuel Type
                                </PdfHeader>
                            </FlexInnerComponent>
                            <FlexInnerComponent width={'55'}>
                                <PdfHeader
                                    color={'#000'} backgroundColor={'#FFF'}
                                    borderRadius={'0 0 16px 0px'}
                                    lineHeight={'none'} fontSize={'13px'}
                                    weight={'500'} className={'text-center  mb-0'}>
                                    {vehicle?.features?.vehicle_fuel?.name}
                                </PdfHeader>
                            </FlexInnerComponent>
                        </FlexInnerComponent>
                    </div>
                </InspectionReportItemsContainer>
            </CardBody>
            <CardBody>
                <InspectionReportItemsContainer>
                    <PdfHeader
                        borderRadius={'16px 16px 0px 0px'}
                        backgroundColor={'#000'}
                        color={'#fff'}
                        lineHeight={'none'}
                        fontSize={'24px'}
                        weight={'700'}
                        className={'text-center mb-1'}>
                        Vehicle Check In
                    </PdfHeader>
                    {
                        inspectionData.map((inspection,index)=>{
                            if (inspection?.type === 'image') {
                              return   inspection.options.length ?
                                 <Fragment key={index}>
                                        <PdfHeader
                                            backgroundColor={'#000'}
                                            color={'#fff'}
                                            lineHeight={'none'}
                                            fontSize={'20px'}
                                            weight={'700'}
                                            className={'text-center mb-0'}>
                                            {
                                                inspection.name
                                            }
                                        </PdfHeader>
                                      <div className={'d-flex g-5 flex-wrap p-3'}>
                                          {
                                              inspection.options.map(item=>{
                                                  return <img src={ item?.preview ? item?.preview : item?.image_link}
                                                              height={'200px'} width={'200px'}/> })
                                          }
                                      </div>
                                    </Fragment> : null

                            }
                                return (
                                    <Fragment key={index}>
                                        <PdfHeader
                                            backgroundColor={'#000'}
                                            color={'#fff'}
                                            lineHeight={'none'}
                                            fontSize={'20px'}
                                            weight={'700'}
                                            className={'text-center mb-0'}>
                                            {
                                                inspection.name
                                            }
                                        </PdfHeader>
                                        <div className={'d-flex'}>
                                            <FlexInnerComponent width={'30'}>
                                                <PdfHeader  className={'text-center  mb-0'} fontSize={'13px'} weight={'600'} lineHeight={'none'} backgroundColor={'#F2F2F2'} color={'#0000008C'}>
                                                    Item
                                                </PdfHeader>
                                            </FlexInnerComponent>
                                            <FlexInnerComponent width={'70'}>
                                                <PdfHeader  className={'text-center  mb-0'} fontSize={'13px'} weight={'600'} lineHeight={'none'} backgroundColor={'#FFF'} color={'#0000008C'}>
                                                    Status
                                                </PdfHeader>
                                            </FlexInnerComponent>
                                        </div>
                                        {
                                            inspection.options.map((item,idx)=>{
                                                return (
                                                    <Fragment key={`${index}_${idx}`}>
                                                        <div className={'d-flex'}>
                                                            <FlexInnerComponent width={'30'}>
                                                                <PdfHeader
                                                                    borderRadius={(inspection[index+1] === undefined && inspection.options[idx+1] == undefined ) ?
                                                                        '0 0 0px 16px' : ''}
                                                                    backgroundColor={'#F2F2F2'} color={'#0000008C'}
                                                                    lineHeight={'none'} fontSize={'13px'}
                                                                    weight={'600'} className={'text-center  mb-0'}>
                                                                    {item.name}
                                                                </PdfHeader>
                                                            </FlexInnerComponent>
                                                            <FlexInnerComponent width={'70'}>
                                                                <PdfHeader
                                                                    color={ '#000'}
                                                                    backgroundColor={(item.status === undefined || item.status === 'checked_ok') ? '#FFF':
                                                                        item.status === 'requires_attention' ? '#EB575740' :
                                                                        '#F3994A40' }
                                                                    borderRadius={(inspection[index+1] === undefined && inspection.options[idx+1] == undefined )
                                                                        ? '0 0 16px 0px' : ''}
                                                                    lineHeight={'none'} fontSize={'14px'}
                                                                    weight={'500'} className={'text-center  mb-0'}>
                                                                    <Dot color={(item.status === undefined || item.status === 'checked_ok') ? 'green'
                                                                        : item.status === 'requires_attention' ? '#EB5757' : '#F3994A'} />
                                                                    {item.status === undefined || item.status === 'checked_ok' ? parseToWord('checked_ok') : parseToWord(item.status) }
                                                                </PdfHeader>
                                                            </FlexInnerComponent>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })
                                        }

                                    </Fragment>
                                )
                        })
                    }

                </InspectionReportItemsContainer>
            </CardBody>

        </Card>
    )
}