import React from "react";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {
    CheckboxContainer, Checkmark,
    CustomHeader,
    CustomTD, IconContainer,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import {parseToWord} from "../../../constants";
import CustomDropdownComponent from "@pages/components/JobDetailsComponents/CustomInspectionDropDown";

export default function  RequiredInspectionList({data, changeData, activeTab}) {
    let { activeData, activeIndex } = data.reduce((result, item, index) => {
        if (parseToWord(item.name) === parseToWord(activeTab)) {
            result.activeData = item;
            result.activeIndex = index;
        }
        return result;
    }, {});
    return (
       <Col lg={12}>
                <Card>
                    <CardHeader className={'border-bottom-0'}>
                        <CustomHeader fontSize={'16px'}>
                            {activeData.name}
                        </CustomHeader>
                    </CardHeader>
                    <CardBody>
                        <StyledTable className={'table-borderless'}>
                            <thead className={'header-white-text'}>
                            <tr>
                                <StyledTableHeaderCell width={'60%'} color={'#0000008C'}>
                                    Type
                                </StyledTableHeaderCell>

                                <StyledTableHeaderCell width={'30%'} color={'#0000008C'}>
                                    Required
                                </StyledTableHeaderCell>
                            </tr>
                            </thead>
                            <tbody>
                            { activeData.list.map((component,idx)=>{
                                return <tr key={`4_${idx}`}>
                                    <CustomTD width={'60%'}>
                                        {component.name}
                                    </CustomTD>

                                    <CustomTD className={'text-center'} width={'30%'}>
                                        <CustomDropdownComponent
                                            border={'1px solid #C0D9FF59'}
                                            selectedTitle={component?.required ? true : false}
                                            statusArrayKey={'checkInStatuses'}
                                            handleChange={(value)=>{
                                                changeData({
                                                    index:activeIndex,
                                                    idx,
                                                    value: value
                                                })
                                            }}
                                        />
                                    </CustomTD>

                                </tr>
                            })
                            }
                            </tbody>
                        </StyledTable>
                    </CardBody>
                </Card>
            </Col>

    )
}