import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useLocation} from "react-router-dom";
import axios from "../../../config/axios";
import * as constants from "../../../constants";
import {Card, CardBody, CardHeader, Col, Modal, Row} from "reactstrap";
import JobCard from "@pages/components/JobDetailsComponents/JobCard";
import QuoteComponent from "@pages/components/JobDetailsComponents/QuoteComponent";
import BackActionComponent from "@pages/components/JobDetailsComponents/BackActionComponent";
import {
    CustomHeader,
    CustomInput,
    FleetButton,
    FlexInnerComponent
} from "@pages/new-design-components/custom-components";
import {CURRENCY} from "../../../constants";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import ServiceRecordPreviewComponent from "@pages/components/JobDetailsComponents/ServiceRecordPreviewComponent";
import {Notification} from "@pages/components/Notification";
import NotificationAlert from "react-notification-alert";
import ServiceRecordComponentForPrint from "@pages/components/JobDetailsComponents/ServiceRecordComponentForPrint";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import QuotePreviewComponentForPrint from "@pages/components/JobDetailsComponents/QuotePreviewComponentForPrint";
import {LoaderContainer, override} from "@pages/reusable-components/styled-components";
import {ClipLoader} from "react-spinners";

export default function AddVehicleRepairQuote() {
   const location = useLocation();
   const [loading,setLoading] = useState(true);
   const [isEdit,setIsEdit] = useState(false);
   const [generating,setGenerating] = useState(false);
   const [inspectionRecord,setShowInspectionRecord] = useState(false);
   const [imageModal,setImageModal] = useState({
       isOpen: false,
       imageUrl: null,
   });
   const notificationAlert = useRef(null);
   const [vehicle,setVehicle] = useState({});
   const [departments,setDepartments] = useState([]);
   const [data,setData] = useState([]);
   const [invoiceData,setInvoiceData] = useState([]);
   const [damages,setDamages] = useState([]);
   const [inspectionData,setInspectionData] = useState([]);
   const [errors,setErrors] = useState({});
   const [partsData,setPartsData] = useState([]);
   const [deleteIds,setDeleteIds] = useState([]);
   const [deletePartIds,setDeletePartIds] = useState([]);
   const [noInsurance,setNoInsurance] = useState(false);
   const [quote,setQuote] = useState({
       sum: null,
       discount: null,
       total: null
   });
   const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const vehicleId = queryParams.get('vehicleId');
    const fleetId = queryParams.get('fleetId');
    const quoteId = queryParams.get('quoteId');
    const calculateSum = () => {
        const dataSum = data.reduce((acc, item) => {
            const cost = parseFloat(item.cost);
            return acc + (isNaN(cost) ? 0 : cost);
        }, 0);

        const partsDataSum = partsData.reduce((acc, item) => {
            const cost = parseFloat(item.cost);
            return acc + (isNaN(cost) ? 0 : cost);
        }, 0);

        return dataSum + partsDataSum;
    };
    useEffect(() => {
        const sum = calculateSum();
        const total = calculateTotalWithDiscount(sum,quote?.discount)
        setQuote({...quote,sum:sum,total:total })
    }, [data, partsData]);

    useEffect(() => {
        if (fleetId && vehicleId) {
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-details`,
                params: {
                    quoteId,
                    fleetId,
                    vehicleId,
                },
            }).then(res=>{
                const q = res.data.data
                setQuote(q)
                setPartsData(q.parts)

                fetchData(q.vehicle_id,q.fleet_id, q.work)
                setInvoiceData(q.work.filter(item=>item.added_to_report))
                setIsEdit(true)
            }).catch(err=>{
                console.log('err:',err)
            })
        }
    },[quoteId,vehicleId,fleetId])

    const fetchData = (vehicleId,fleetId,work = []) => {
        axios({
            method: 'GET',
            url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-vehicle`,
            params: {
                vehicleId,
                fleetId,
            }
        }).then(res=>{
            const tempData = res.data.data
            setNoInsurance(tempData?.insureance_written_off)
            axios({
                method: 'GET',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/get-required-inspection`,
            }).then(resource=>{
                setDamages(tempData.damages)
                setData(work)
                let requiredData = resource.data.data;
                requiredData = requiredData.map(item => {
                    item.list = item.list.map(listItem => {
                        tempData.requiredInspectionResult.map(res=>{
                            if (res?.required_inspection_id === listItem.id) {
                                listItem.option_id = res.id;
                                listItem.required = res?.required === 1;
                            }
                        })
                        return listItem;
                    });
                    return item;
                });
                setInspectionData(requiredData)
                setLoading(false)
            }).catch(err=>{
                setLoading(false)
            })
        }).catch(err=>{
            setLoading(false)
        })
    }
    const addNewRow = () => {
        let tempData = [...data]
        let row =  {
            title: null,
            hour: null,
            cost: null,
            department: null,
            added_to_report: 0,
        }
        tempData.push(row)
        setData(tempData)
    }
    const addNewPartsRow = () => {
        let tempData = [...partsData]
        let row =  {
            part_number: null,
            part: null,
            cost: null,
            department: null,
            added_to_report: 0,
            status: 'to_do',
        }
        tempData.push(row)
        setPartsData(tempData)
    }


    const changeData = ({index,key,value = null}) => {
        let tempData = [...data]
        if (key === 'added_to_report') {
            tempData[index]['added_to_report'] = tempData[index]['added_to_report'] === 0 || tempData[index]['added_to_report'] === null ? 1 : 0;
        } else {
            tempData[index][key] = value
        }
        if (tempData[index]?.id) {
            tempData[index]['edited'] = true
        }
        setData(tempData)
    }
    const changePartsData = ({index,key,value = null}) => {
        let tempData = [...partsData];
        if (key === 'added_to_report') {
            tempData[index]['added_to_report'] = tempData[index]['added_to_report'] === 0 || tempData[index]['added_to_report'] === null ? 1 : 0;
        } else {
            tempData[index][key] = value;
        }

        if (tempData[index]?.id) {
            tempData[index]['edited'] = true;
        }

        setPartsData(tempData);
    }

   useEffect(()=>{
      if (vehicleId) {
         try {
           axios({
               method: 'GET',
               url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-quote-vehicle`,
                 params: {
                    vehicleId,
                 },
            }).then(res=>{
               const data = res.data.data
               setVehicle(data.vehicle)
               setDepartments(data.departments)
               setLoading(false)
           }).catch(err=>{
              setLoading(false)
           })

         } catch (error) {
            setLoading(false)
            if (axios.isCancel(error)) {
               console.log('Request canceled :: ', error)
            } else {
               console.error('error :: ', error)
            }
         }
      }
   },[vehicleId])

    const deletePartsRow = ({index,id}) => {
        let tempData = [...partsData];
        let tempIds = [...deletePartIds]
        if (id && !tempIds.includes(id)) {
            tempIds.push(id)
            setDeletePartIds(tempIds)
        }
        tempData.splice(index, 1);
        setPartsData(tempData)
    }
    const deleteRow = ({index,id}) => {
        let tempData = [...data];
        let tempIds = [...deleteIds]
        if (id && !tempIds.includes(id)) {
            tempIds.push(id)
            setDeleteIds(tempIds)
        }
        tempData.splice(index, 1);
        setData(tempData)
    }
    const redirectToManagement = () => {
        history.goBack();
    }
    const generateQuote = async () => {
        const response = await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/generate-quote`,{
            id: quote?.id
        })
        if (response.status === 200)
        {
            setGenerating(false)
            Notification('success', 'Success', 'Successfully Created ',notificationAlert);
        } else {
            setGenerating(false)
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    }
    const saveData = async () => {
        let invalidData = {}

        data.forEach((item,index)=> {
            if (!item.title || !item.department) {
                if (!invalidData.hasOwnProperty('data')) {
                    invalidData['data'] = {}
                }
                if (!invalidData['data'].hasOwnProperty(index)) {
                    invalidData['data'][index] = []
                }
                invalidData['data'][index].push('title')
                invalidData['data'][index].push('department')
            }
        })
        partsData.forEach((item,index)=> {
            if (!item.part || !item.department) {
                if (!invalidData.hasOwnProperty('partsData')) {
                    invalidData['partsData'] = {}
                }
                if (!invalidData['partsData'].hasOwnProperty(index)) {
                    invalidData['partsData'][index] = []
                }
                invalidData['partsData'][index].push('title')
                invalidData['partsData'][index].push('department')
            }
        })

        if (Object.keys(invalidData).length === 0) {
            setErrors({})
            setLoading(true)
            const created = data.filter(item => item.id === undefined);
            const updated = data.filter(item => item.edited === true);
            const createdPartsData = partsData.filter(item => item.id === undefined);
            const updatedPartsData = partsData.filter(item => item.edited === true);

            try {
                const response = await axios.post(`${constants.FLEET_MS_API_URL}/fleet-management/create-quote`,{
                    work: [...created,...updated],
                    parts: [...createdPartsData,...updatedPartsData],
                    deletedWorks: [...deleteIds],
                    deletedParts: [...deletePartIds],
                    quote,
                    fleetId,
                    vehicleId,
                })
                if (response.status === 200) {
                    setLoading(false)
                }
            } catch (error) {
                setLoading(false)
                Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
                console.error('Error:', error);
            }
        }
        else {
            setErrors(invalidData)
        }

    }

    const calculateTotalWithDiscount = (sum, discount = 0) => {
        return sum + (sum * (discount / 100));
    };
    const showMessage = (positive) => {
        if (positive) {
            Notification('success', 'Success', 'Successfully uploaded ',notificationAlert);
        }else  {
            Notification('warning', 'Warning', 'Something went wrong please try again later!',notificationAlert);
        }
    }
    const handleGeneratePDF = () => {
        const input = document.getElementById('pdf-content-component');

        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');

            const pdf = new jsPDF('p', 'mm', 'a4');
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('generated-pdf.pdf');
        });
    };
    const sendPdf = () => {
        const input = document.getElementById('pdf-content-component');

        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png',0.5);
            const pdf = new jsPDF('p', 'mm', [220, 500]);
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * pdfWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }
            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            const pdfBlob = pdf.output('blob');
            const formData = new FormData();
            const pdfFileName = `vehicle_${vehicleId}_generated.pdf`;
            formData.append('vehicle_id', vehicleId);
            formData.append('type', 'service_report');
            formData.append('pdf', pdfBlob, pdfFileName);
            axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/save-report`,
                data: formData
            }).then(res=>{
                showMessage(true)
            }).catch(err=>{
                showMessage(false)
            })
        });
    };

    const sendQuotePdf = () => {
        setGenerating(true)
        const input = document.getElementById('pdf-content'); // The content you want to convert to PDF
        html2canvas(input, {
            scale: 1,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png',0.5);

            const pdf = new jsPDF('p', 'mm', [220, 500]);
            let pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgWidth = pdfWidth;
            let imgHeight = (canvas.height * imgWidth) / canvas.width;

            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

            const pdfBlob = pdf.output('blob');

            const formData = new FormData();

            const pdfFileName = `vehicle_${vehicleId}_generated.pdf`;
            formData.append('vehicle_id', vehicleId);
            formData.append('email', vehicle?.email);
            formData.append('pdf', pdfBlob, pdfFileName);
            formData.append('type', 'repair_report');
            axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/save-report`,
                data: formData
            }).then(res=>{
                generateQuote();
            }).catch(err=>{
                setGenerating(false)
                Notification('warning', 'Error', 'Something went wrong please try again later!',notificationAlert);
             })
        });
    };
    const toggleImageModal = () => {
        setImageModal({...imageModal,isOpen: !imageModal.isOpen})
    }
    const viewImageModal = (url) => {
        setImageModal({isOpen: true,imageUrl: url})
    }
    const updateFleet = async () => {
        try {

            const response = await axios({
                method: 'POST',
                url: `${constants.FLEET_MS_API_URL}/fleet-management/fleet-insureance-update/${fleetId}`,
                data: {
                    insureance_written_off: !noInsurance,
                },
            })
            if (response.status === 200) {
              setNoInsurance(!noInsurance)
                Notification('success', 'Success', 'Successfully updated ',notificationAlert);
            }

        }catch (e) {
            Notification('warning', 'Error', 'Something went wrong please try again later!',notificationAlert);
        }
    }
   return (
       loading ?  <LoaderContainer>
               <ClipLoader
                   css={override}
                   size={40}
                   color={`#7B61E4`}
                   loading={loading}
               />
           </LoaderContainer>
           :
           <div className={'container-fluid mt-3'}>
               <Modal isOpen={imageModal.isOpen} className={'modal-dialog-centered modal-lg'} toggle={toggleImageModal}>
                   <img src={imageModal.imageUrl} width={'100%'} alt=""/>
               </Modal>
               <div className="rna-wrapper">
                   <NotificationAlert ref={notificationAlert}/>
                   <QuotePreviewComponentForPrint
                       data={invoiceData}
                       quote={quote}
                       vehicle={vehicle}
                       inspectionData={inspectionData}
                       damages={damages}
                       parsable={true}/>
               </div>
               {
                   inspectionRecord ? <>
                           <ServiceRecordPreviewComponent backToList={()=>{setShowInspectionRecord(false)}}
                                                          vehicle={vehicle}
                                                          vehicleId={vehicleId}

                                                          sendPdf={sendPdf}
                                                          handleGeneratePDF={handleGeneratePDF}
                                                          partsData={partsData.filter(item=>item.added_to_report)}
                                                          data={data.filter(item=>item.added_to_report)}
                                                          totalLabour={data.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                                              return accumulator + (parseFloat(part.hour));
                                                          }, 0)}
                                                          dataTotal={data.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                                              return accumulator + (part.cost);
                                                          }, 0)}
                                                          total={partsData.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                                              return accumulator + part.cost;
                                                          }, 0)}
                           />
                           <ServiceRecordComponentForPrint
                               parsable={true}
                               vehicle={vehicle}
                               partsData={partsData.filter(item=>item.added_to_report)}
                               data={data.filter(item=>item.added_to_report)}
                               totalLabour={data.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                   return accumulator + (parseFloat(part.hour));
                               }, 0)}
                               dataTotal={data.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                   return accumulator + (part.cost);
                               }, 0)}
                               total={partsData.filter(item=>item.added_to_report)?.reduce((accumulator, part) => {
                                   return accumulator + (part.cost);
                               }, 0)}
                           />
                       </>
               :   <>
                           <BackActionComponent backAction={redirectToManagement} text={'Back to overview'}/>
                           <div className={'d-flex justify-content-between flex-wrap'}>
                               <CustomHeader lineHeight={'unset'} >{location.pathname === '/admin/job-card' ? 'Job Card' : 'Repair Quote'} </CustomHeader>
                               <div className={'d-flex flex-wrap g-10'}>
                                   {
                                       isEdit ?
                                           <>
                                               <FleetButton
                                                   height={'40px'}
                                                   width={'224px'}
                                                   padding={'0 7px 0 0'}
                                                   backgroundColor={'#F3994A'}
                                                   radius={'8px'}
                                                   onClick={()=>{
                                                       setShowInspectionRecord(true)
                                                   }}
                                               >
                                                   <IcoMoon
                                                       className={'cursor-pointer'}
                                                       iconSet={IconSet}
                                                       icon={'icont-wrench'}
                                                       width={'50px'}
                                                       height={'20px'}
                                                   />
                                                   View Service Record
                                               </FleetButton>
                                               <FleetButton
                                                   height={'40px'}
                                                   width={'164px'}
                                                   backgroundColor={'#219852'}
                                                   radius={'8px'}
                                                   onClick={saveData}
                                               >
                                                   Save
                                               </FleetButton>
                                           </>
                                           :
                                           <>
                                               <FleetButton
                                                   height={'40px'}
                                                   width={'164px'}
                                                   backgroundColor={"#219852"}
                                                   radius={'8px'}
                                                   onClick={saveData}
                                               >
                                                   Save
                                               </FleetButton>
                                           </>
                                   }
                               </div>
                           </div>
                           <Row className={'mt-4'}>
                               <Col lg={3}>
                                   <JobCard data={vehicle} noInsurance={noInsurance} updateFleet={updateFleet}/>
                                   <Card className={'fleet-details-container'}>
                                       <CardHeader className={'border-bottom-0'}>
                                           <div className={'d-flex justify-content-between'}>
                                               <CustomHeader fontSize={'16px'}>
                                                   Quote Total
                                               </CustomHeader>

                                           </div>
                                       </CardHeader>
                                       <CardBody>
                                           <div className={'d-flex justify-content-between align-items-center'}>
                                               <FlexInnerComponent  width={'20'}>
                                                   <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                                       Sum
                                                   </CustomHeader>
                                               </FlexInnerComponent>

                                               <FlexInnerComponent width={'70'}>
                                                   <CustomHeader  lineHeight={'none'} fontSize={'14px'} weight={'400'} color={'#000000'}>
                                                       {CURRENCY}  {quote?.sum}
                                                   </CustomHeader>
                                               </FlexInnerComponent>

                                           </div>
                                           <div className={'d-flex justify-content-between align-items-center'}>
                                               <FlexInnerComponent  width={'30'}>
                                                   <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                                       Discount  %
                                                   </CustomHeader>
                                               </FlexInnerComponent>

                                               <FlexInnerComponent width={'60'}>
                                                   <CustomInput radius={'8px'}
                                                                onChange={(e)=>{
                                                                    const total = calculateTotalWithDiscount(quote?.sum,e.target.value)
                                                                    setQuote({...quote,discount:e.target.value,total: total})
                                                                 }
                                                                }
                                                                border={'1px solid #C0D9FF59'}
                                                                value={quote?.discount} />
                                               </FlexInnerComponent>

                                           </div>
                                           <div className={'d-flex justify-content-between align-items-center'}>
                                               <FlexInnerComponent  width={'20'}>
                                                   <CustomHeader lineHeight={'none'} fontSize={'14px'} weight={'600'} color={'#0000008C'}>
                                                       Sum
                                                   </CustomHeader>
                                               </FlexInnerComponent>

                                               <FlexInnerComponent width={'70'}>
                                                   <CustomHeader  lineHeight={'none'} fontSize={'14px'} weight={'750'} color={'#000000'}>
                                                       {CURRENCY}  { quote?.total}
                                                   </CustomHeader>
                                               </FlexInnerComponent>

                                           </div>
                                       </CardBody>
                                       <div className={'d-flex w-100 align-items-center justify-content-center mb-3'}>
                                           <FleetButton
                                               height={'40px'}
                                               width={'90%'}
                                               disabled={generating}
                                               backgroundColor={'#000'}
                                               radius={'24px'}
                                               onClick={sendQuotePdf}
                                           >
                                               Generate Quote
                                           </FleetButton>
                                       </div>
                                   </Card>

                               </Col>
                               <Col lg={9}>
                                   <QuoteComponent departments={departments}
                                                   data={data}
                                                   errors={errors}
                                                   addNewRow={addNewRow}
                                                   damages={damages}
                                                   changeData={changeData}
                                                   viewImage={viewImageModal}
                                                   partsData={partsData}
                                                   deleteRow={deleteRow}
                                                   deletePartsRow={deletePartsRow}
                                                   changePartsData={changePartsData}
                                                   addNewPartsRow={addNewPartsRow}/>
                               </Col>
                           </Row>
                       </>
               }

           </div>
   )
}