import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {
    CheckboxContainer,
    Checkmark,
    CustomHeader,
    CustomInput,
    FleetButton
} from "@pages/new-design-components/custom-components";
import React, {useState} from "react";

export default function JobCard({data, saveVehicleDetails, workerDetails = true,noInsurance = false,updateFleet}) {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        make: data.make.name,
        model: data.model.name,
        stock_no: data.stock_no,
        current_mileage: data.current_mileage,
        colour: data.colour,
        first_name: data.first_name,
        address: data.address,
        mobile_number: data.mobile_number,
        email: data.email,
        worker: data.worker,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Toggle edit mode
    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };
    return (
            <Card className={'fleet-details-container'}>
                <CardHeader className={'border-bottom-0'}>
                   <div className={'d-flex justify-content-between'}>
                       <CustomHeader fontSize={'16px'}>
                           Fleet Details
                       </CustomHeader>
                       <img className={'cursor-pointer'} height='25px' width='25px'
                            onClick={handleEditToggle} src={require(`assets/images/edit.svg`)} alt=""/>
                   </div>
                </CardHeader>
                <CardBody>
                    <img src="" alt="" className={'fleet-task-image'} height={'164px'}/>
                    <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'600'} className={'mb-0'}>
                            {`${data.make.name} ${data.model.name}`}
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {`${data.make.name} ${data.model.name}`}
                        </CustomHeader>
                    </div>
                    <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                            Registration Number
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {`${data.stock_no}`}
                        </CustomHeader>
                    </div>
                    <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                            Mileage
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {isEditing ? (
                                <CustomInput
                                    border={'1px solid #C0D9FF59'}
                                    type="number"
                                    name="current_mileage"
                                    value={formData.current_mileage}
                                    onChange={handleChange}
                                />
                            ) : (
                                `${formData.current_mileage}`
                            )}
                        </CustomHeader>
                    </div>
                    <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                            Colour
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {isEditing ? (
                                <CustomInput
                                    border={'1px solid #C0D9FF59'}
                                    type="text"
                                    name="colour"
                                    value={formData.colour}
                                    onChange={handleChange}
                                />
                            ) : (
                                `${formData.colour}`
                            )}
                        </CustomHeader>
                    </div>
                    <div className={'d-flex flex-column'}>
                        <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'}>
                            Customer
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {`${data.first_name}`}
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {`${data.address}`}
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {`${data.mobile_number}`}
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            {`${data.email}`}
                        </CustomHeader>
                    </div>

                    {workerDetails && <div className={'d-flex g-10'}>
                        <CustomHeader weight={'700'} fontSize={'14px'} className={'mb-0'} lineHeight={'18px'}>
                            Insurance written off
                        </CustomHeader>
                        <CustomHeader fontSize={'14px'} weight={'400'} color={'#979797'}>
                            <CheckboxContainer checked={noInsurance} backgroundColor={'red'} onClick={()=>{
                                updateFleet()
                            }
                            }>
                                <Checkmark checked={noInsurance} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M20.285 6.285a1 1 0 00-1.415 0L10 15.585 5.715 11.3a1 1 0 00-1.415 1.415l5 5a1 1 0 001.415 0l10-10a1 1 0 000-1.415z" />
                                </Checkmark>
                            </CheckboxContainer>
                        </CustomHeader>
                    </div>}


                    {isEditing &&
                         <FleetButton
                             height={'40px'}
                             width={'164px'}
                             backgroundColor={'#F3994A'}
                             radius={'8px'}
                             onClick={()=>{
                                 saveVehicleDetails(formData)
                                 setIsEditing(false)
                             }}
                         >
                             Save
                         </FleetButton>
                    }
                </CardBody>
            </Card>
    )
}
