import React, {useEffect, useRef, useState} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    AddImageButton,
    CheckboxContainer,
    Checkmark, CrossIconContainer,
    CustomHeader, CustomInput,
    CustomTD, FleetButton, IconContainer, StyledImage,
    StyledTable,
    StyledTableHeaderCell
} from "@pages/new-design-components/custom-components";
import IcoMoon from "react-icomoon";
import IconSet from "@assets/selection.json";
import CustomCheckbox from "@pages/components/JobDetailsComponents/CustomCheckbox";


export default function BodyShop({   data,
                                     changeData,
                                     deleteDocument,
                                     onFileSelect,
                                     partsData,
                                     changePartsData,
                                     deletePartsRow,
                                     addNewPartsRow,
                                     department,
                                     addNewRow
                                 }) {

    const handleButtonClick = (id) => {
        document.getElementById(id).click();
    };
    const handleFileChange = (event,index) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file,index);  // Pass the selected file to the parent component
        }
    };
    return (
        <Col lg={8}>
            <Card className={'mb-3'}>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Work Records
                    </CustomHeader>
                </CardHeader>
                <CardBody>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Completion
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'60%'} color={'#0000008C'}>
                                {department === 'workshop' ? 'Tasks' : 'Job Performed'}
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell width={'5%'}>

                            </StyledTableHeaderCell>
                   
                        </tr>
                        </thead>
                        <tbody>
                        {data.length ?
                            data.map((item, index)=>{
                                return <tr key={`4_${index}`}>
                                    <CustomTD className={'text-center'} width={'20%'}>
                                        <CustomCheckbox checked={item.status == 'completed'} onClick={()=>{
                                            changeData({
                                                index,
                                                key: 'status'
                                            })
                                        }}/>
                                    </CustomTD>

                                    <CustomTD width={'60%'}>
                                        <CustomInput radius={'8px'}
                                                     onChange={(e)=>{
                                                         changeData({
                                                             index,
                                                             key: 'title',
                                                             value: e.target.value
                                                         })
                                                     }
                                                     }
                                                     border={'1px solid #C0D9FF59'}
                                                     value={item.title} />
                                    </CustomTD>
                                    <CustomTD width={'5%'}>
                                        {
                                            item?.documents?.length ?
                                                <div className={'position-relative'}>
                                                    <CrossIconContainer>
                                                        <IcoMoon
                                                            iconSet={IconSet}
                                                            icon={'cross'}
                                                            size={13}
                                                            onClick={() => {
                                                                deleteDocument(item?.documents[0]?.id,index)
                                                            }}
                                                        />
                                                    </CrossIconContainer>
                                                    <StyledImage width={'40px'}
                                                                 height={'40px'}
                                                                 src={item?.documents[0]?.path ? item?.documents[0]?.path : item?.documents[0]?.preview } alt=""/>
                                                </div> :
                                                <AddImageButton onClick={()=>handleButtonClick(`input_${index}`)}>
                                                    +
                                                    <input
                                                        type="file"
                                                        id={`input_${index}`}
                                                        style={{ display: 'none' }}
                                                        onChange={(e)=>{
                                                            handleFileChange(e,index)
                                                        }}
                                                    />
                                                </AddImageButton>
                                        }

                                    </CustomTD>

                                </tr>
                            })
                            : null
                        }
                        <tr>
                            <td colSpan="4" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    hoverColor={'#F3994A'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewRow}
                                >
                                    &#43;  Add new
                                </FleetButton>
                            </td>
                        </tr>

                        </tbody>

                    </StyledTable>

                </CardBody>
            </Card>

            <Card>
                <CardHeader className={'border-bottom-0'}>
                    <CustomHeader fontSize={'16px'}>
                        Parts List
                    </CustomHeader>
                </CardHeader>
                <CardBody>
                    <StyledTable className={'table-borderless'}>
                        <thead className={'header-white-text'}>
                        <tr>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'45%'} color={'#0000008C'}>
                                Item
                            </StyledTableHeaderCell>

                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'45%'} color={'#0000008C'}>
                                No.
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'20%'} color={'#0000008C'}>
                                Ordered
                            </StyledTableHeaderCell>
                            <StyledTableHeaderCell fontSize={'14px'} className={'text-center'} width={'10%'}>
                            </StyledTableHeaderCell>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            partsData.length ?
                                partsData.map((item,index)=>{
                                    return  <tr>
                                        <CustomTD className={'text-center'} width={'45%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'part',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={'1px solid #C0D9FF59'}
                                                         value={item.part} />
                                        </CustomTD>
                                        <CustomTD className={'text-center'} width={'45%'}>
                                            <CustomInput radius={'8px'}
                                                         onChange={(e)=>{
                                                             changePartsData({
                                                                 index,
                                                                 key: 'part_number',
                                                                 value: e.target.value
                                                             })
                                                         }
                                                         }
                                                         border={'1px solid #C0D9FF59'}
                                                         value={item.part_number} />
                                        </CustomTD>
                                        <CustomTD width={'20%'}>
                                            <CustomCheckbox checked={item.status === 'completed'} onClick={()=>{
                                                changePartsData({
                                                    index,
                                                    key: 'status',
                                                    value: item.status === 'completed' ? 'to_do' : 'completed',
                                                });
                                            }
                                            }/>
                                        </CustomTD>
                                        <CustomTD width={'10%'}>
                                            <IconContainer>
                                                <IcoMoon
                                                    className={'cursor-pointer'}
                                                    iconSet={IconSet}
                                                    icon={'delete_new_outline'}
                                                    size={20}
                                                    onClick={() => {
                                                        deletePartsRow(index)
                                                    }}
                                                />
                                            </IconContainer>

                                        </CustomTD>
                                    </tr>
                                })
                                 : null
                        }
                        <tr>
                            <td colSpan="4" className={'text-center'}>
                                <FleetButton
                                    height={'43px'}
                                    width={'114px'}
                                    backgroundColor={'#FDF0E4'}
                                    hoverColor={'#F3994A'}
                                    color={'#F3994A'}
                                    fontSize={'14px'}
                                    radius={'32px'}
                                    onClick={addNewPartsRow}
                                >
                                    &#43;  Add new
                                </FleetButton>
                            </td>
                        </tr>
                        </tbody>
                    </StyledTable>
                </CardBody>
            </Card>
        </Col>
    )
}