import * as actionTypes from './actions';
import produce from 'immer';

const initialState = {
    data: [],
    workRecordsCreatedSuccess: false,
    workRecordsCreatedFailure: false,

    workRecordsUpdatedSuccess: false,
    workRecordsUpdatedFailure: false,

    workRecordsDeletedSuccess: false,
    workRecordsDeletedFailure: false,

    partsListCreatedSuccess: false,
    partsListCreatedFailure: false,

    partsListUpdatedSuccess: false,
    partsListUpdatedFailure: false,

    partsListDeletedSuccess: false,
    partsListDeletedFailure: false,

    deleteFleetFailure: false,
    deleteFleetSuccess: false,

    getQuickDataSuccess: false,
    getQuickDataFailure: false,
    quickViewData: [],

};

const fleetState = produce((draft = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_FLEET_REQUEST:
            draft.deleteFleetSuccess = false;
            draft.deleteFleetFailure = false;
            break;
        case actionTypes.DELETE_FLEET_SUCCESS:
            draft.deleteFleetSuccess = true;
            draft.deleteFleetFailure = false;
            break;
        case actionTypes.DELETE_FLEET_FAILURE:
            draft.deleteFleetSuccess = false;
            draft.deleteFleetFailure = true;
            break;
        case actionTypes.GET_QUICK_DATA_REQUEST:
            draft.getQuickDataSuccess = false;
            draft.getQuickDataFailure = false;
            draft.quickViewData  = [];
            break;
        case actionTypes.GET_QUICK_DATA_SUCCESS:
            draft.getQuickDataSuccess = true;
            draft.getQuickDataFailure = false;
            draft.quickViewData  = [...action.data];
            break;
        case actionTypes.GET_QUICK_DATA_FAILURE:
            draft.getQuickDataSuccess = false;
            draft.getQuickDataFailure = true;
            draft.quickViewData  = [];
            break;
        default:
            return draft;
    }
});

export default fleetState;